import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './styles.css';

interface Props {
  label: string;
  error?: boolean;
  value: Date | null;
  onChange: (date: Date | null, value?: string | null | undefined) => void;
}

const Index: React.FC<Props> = ({ label, error, value, onChange }: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className="customDatePicker"
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        margin="dense"
        label={label}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        error={error}
      />
    </MuiPickersUtilsProvider>
  );
};

Index.defaultProps = {
  error: false,
};

export default Index;
