import React, { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import SubContentViewProfile from 'components/SubContentViewProfile';
import CardFlex from 'components/CardFlex';
import DeleteButton from 'components/DeleteButton';
import MenuItem from '@material-ui/core/MenuItem';
import InvalidityMsg from 'components/InvalidityMsg';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import Modal from 'components/Modal';
import Cookies from 'js-cookie';
import api from 'services/api';
import swal from 'sweetalert';
import { Context } from 'context/authContext';
import ModalDelete from '../ModalDelete';

export interface INetwork {
  id: number;
  room: string;
  player: string;
  createdAt: Date;
}

const Networks: React.FC = () => {
  const userId = Cookies.get('sigma_userid');
  const { checkAuth } = useContext(Context);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editNetwork, setEditNetwork] = useState<boolean>(false);
  const [openModalNetwork, setOpenModalNetwork] = useState<boolean>(false);
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  const [networkToDelete, setNetworkToDelete] = useState({
    id: '',
    room: '',
  });

  const [newNetwork, setNewNetwork] = useState({
    room: { value: '', invalidity: '' },
    player: { value: '', invalidity: '' },
  });

  const cleanNewNetwork = (): void => {
    setNewNetwork({
      room: { value: '', invalidity: '' },
      player: { value: '', invalidity: '' },
    });
  };

  const toggleEditNetwork = (): void => {
    setEditNetwork(!editNetwork);
  };

  const toggleModalNetwork = (): void => {
    if (openModalNetwork) {
      cleanNewNetwork();
    }

    setOpenModalNetwork(!openModalNetwork);
  };

  const changeRoomNetwork = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const { value } = event.target;
    const oldRoom = newNetwork.room;
    const newRoom = { ...oldRoom, value: value as string };

    setNewNetwork({ ...newNetwork, room: newRoom });
  };

  const changePlayerNetwork = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    const oldPlayer = newNetwork.player;
    const newPlayer = { ...oldPlayer, value };

    setNewNetwork({ ...newNetwork, player: newPlayer });
  };

  const addNetwork = (): void => {
    const { room, player } = newNetwork;
    room.invalidity = room.value ? '' : 'Escolha uma sala';
    player.invalidity = player.value ? '' : 'Digite o nome';

    setNewNetwork({ room, player });

    if (!room.invalidity && !player.invalidity) {
      api
        .post(`profiles/${userId}/networks`, {
          room: room.value,
          player: player.value,
        })
        .then(() => {
          setReload((_reload) => !_reload);
          swal('Adicionado com sucesso!', '', 'success');
        })
        .catch((error) => {
          const msg = error.response?.data.detail || error.message;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
    }

    toggleModalNetwork();
  };

  const deleteNetwork = (id: string): void => {
    api
      .delete(`profiles/${userId}/networks/${id}`)
      .then(() => {
        swal('A sala foi excluída', '', 'success');
        setReload((_reload) => !_reload);
        setNetworkToDelete({ id: '', room: '' });
      })
      .catch((error) => {
        setNetworkToDelete({ id: '', room: '' });
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  };

  const mapBackendNetworks = (
    backendNetwork: Record<string, string>
  ): INetwork => ({
    id: parseInt(backendNetwork.id, 10),
    room: backendNetwork.room,
    player: backendNetwork.player,
    createdAt: new Date(backendNetwork.created_at),
  });

  useEffect(() => {
    api
      .get(`profiles/${userId}/networks`)
      .then((resp) => {
        setNetworks(resp.data.map(mapBackendNetworks));
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
  }, [reload, userId, checkAuth]);

  return (
    <>
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 400"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100" height="40" />
            <rect x="0" y="50" rx="10" ry="10" width="90%" height="25" />
            <rect x="0" y="120" rx="10" ry="10" width="13%" height="30" />
            <rect x="400" y="120" rx="10" ry="10" width="15%" height="30" />
            <rect x="800" y="120" rx="10" ry="10" width="17%" height="30" />
            <rect x="0" y="170" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="220" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="270" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="350" rx="10" ry="10" width="90" height="35" />
          </ContentLoader>
        </div>
      ) : (
        <>
          <SubContentViewProfile
            editMode={editNetwork}
            onClick={() => toggleEditNetwork()}
            mainFunction={() => toggleModalNetwork()}
            type="network"
            title="SALAS"
            description="Para cadastrar uma nova sala é necessário que seu nome esteja público no Sharkscope."
          >
            <CardFlex title>
              <div>
                <span>Sala</span>
              </div>
              <div style={{ flex: 2 }}>
                <span>Nome</span>
              </div>
              <div style={{ flex: 2 }}>
                <span>Adicionado em</span>
              </div>
            </CardFlex>
            {networks.map((item) => {
              return (
                <CardFlex key={item.id}>
                  <div>
                    <span>{item.room}</span>
                  </div>
                  <div style={{ flex: 2 }}>
                    <span>{item.player}</span>
                  </div>
                  <div style={{ flex: 2 }}>
                    <span>{item.createdAt.toLocaleString()}</span>
                  </div>
                  {editNetwork && (
                    <div style={{ position: 'absolute', top: -8, right: -8 }}>
                      <DeleteButton
                        small
                        onClick={() => {
                          setNetworkToDelete({
                            id: String(item.id),
                            room: item.room,
                          });
                        }}
                      />
                    </div>
                  )}
                </CardFlex>
              );
            })}
          </SubContentViewProfile>
          <Modal open={openModalNetwork} setOpen={() => toggleModalNetwork()}>
            <div className="modal">
              <div style={{ marginBottom: 20, fontWeight: 'bold' }}>
                <span>ADICIONAR SALA</span>
              </div>
              <CustomSelect
                label="Sala"
                id="sala"
                name="sala"
                error={newNetwork.room.invalidity}
                onChange={changeRoomNetwork}
                value={newNetwork.room.value}
              >
                <MenuItem value="PokerStars">PokerStars</MenuItem>
                <MenuItem value="PartyPoker">PartyPoker</MenuItem>
                <MenuItem value="GGNetwork">GGNetwork</MenuItem>
                <MenuItem value="iPoker">iPoker</MenuItem>
                <MenuItem value="WPN">WPN</MenuItem>
                <MenuItem value="888Poker">888Poker</MenuItem>
                <MenuItem value="Chico">Chico</MenuItem>
              </CustomSelect>
              <InvalidityMsg msg={newNetwork.room.invalidity} />
              <CustomInput
                label="Nome"
                id="nome"
                name="nome"
                error={newNetwork.player.invalidity}
                onChange={changePlayerNetwork}
                value={newNetwork.player.value}
              />
              <InvalidityMsg msg={newNetwork.player.invalidity} />
              <div style={{ marginTop: 20 }}>
                <CustomButton
                  onClick={() => {
                    addNetwork();
                  }}
                  text="ADICIONAR"
                />
              </div>
            </div>
          </Modal>
          <ModalDelete
            open={networkToDelete.id !== ''}
            onConfirm={() => deleteNetwork(networkToDelete.id)}
            title="EXCLUIR SALA"
            message={
              <p>
                Excluir a sala <u>{networkToDelete.room}</u>?
              </p>
            }
            onClose={() => setNetworkToDelete({ id: '', room: '' })}
          />
        </>
      )}
    </>
  );
};

export default Networks;
