import React from 'react';

import './styles.css';

interface Props {
  msg: string;
}

const index: React.FC<Props> = (props) => {
  return props.msg ? (
    <div className="invalidityMsg">
      <span>{props.msg}</span>
    </div>
  ) : null;
};

export default index;
