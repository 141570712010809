import React, { InputHTMLAttributes } from 'react';
import TextField from '@material-ui/core/TextField';
import './styles.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error: string;
}

const index: React.FC<Props> = (props) => {
  return (
    <div className="signInput">
      <TextField
        inputProps={{ ...props }}
        label={props.label}
        fullWidth
        variant="outlined"
        margin="dense"
        error={props.error !== ''}
      />
    </div>
  );
};

export default index;
