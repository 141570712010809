import React, { useState, useEffect, useContext } from 'react';
import CardDashboard from 'components/CardDashboard';
import '../styles.css';
import {
  CardViewType,
  cardStyles,
  horizontalCardStyles,
} from 'components/ViewCards';
import api from 'services/api';
import swal from 'sweetalert';

import { Context } from 'context/authContext';

interface Props {
  view: CardViewType;
  setViewFunction: (view: CardViewType) => void;
}

const Index: React.FC<Props> = ({ view, setViewFunction }: Props) => {
  const { checkAuth } = useContext(Context);
  const [transactionsCard, setTransactionsCard] = useState({
    total: 0,
    roi: 0,
  });
  const [manageCard, setManageCard] = useState({
    coverage: 0,
    pendings: 0,
  });
  const [profileCard, setProfileCard] = useState({
    name: 'Player Name',
    networks: 0,
  });

  useEffect(() => {
    api
      .get(`summary/`)
      .then((resp) => {
        const { transactions, manage, profile } = resp.data;
        setTransactionsCard({
          total: transactions.total,
          roi: transactions.roi,
        });
        setManageCard({ coverage: manage.coverage, pendings: manage.pendings });
        setProfileCard({ name: profile.name, networks: profile.networks });
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  }, [view, checkAuth]);

  return (
    <>
      <div className="cardsView">
        <CardDashboard
          primKey="TORNEIOS"
          primValue={transactionsCard.total}
          secKey="ROI"
          secValue={transactionsCard.roi}
          secValueR="%"
          active={view === 'tournaments'}
          onClick={() => setViewFunction('tournaments')}
          style={cardStyles}
        />
        {/* <CardDashboard
          primKey="MAKEUP"
          secKey="CAIXA"
          primValue={`$${-134408}`}
          primValueR={`${3}%`}
          secValue={`$${112251}`}
          secValueR={`${162}%`}
          active={view === 'makeup'}
          onClick={() => setViewFunction('makeup')}
          style={cardStyles}
        /> */}
        <CardDashboard
          primKey="COBERTURA"
          secKey="PENDÊNCIAS"
          primValue={manageCard.coverage}
          primValueR="%"
          secValue={manageCard.pendings}
          active={view === 'manage'}
          onClick={() => setViewFunction('manage')}
          style={cardStyles}
        />
        <CardDashboard
          primKey="PERFIL"
          secKey="SALAS"
          primValue={profileCard.name}
          secValue={profileCard.networks}
          active={view === 'profile'}
          onClick={() => setViewFunction('profile')}
          style={cardStyles}
        />
      </div>
      {/* <div className="cardsView">
        <CardDashboard
          primKey="PERFIL"
          secKey="SALAS"
          primValue={profileCard.name}
          secValue={profileCard.networks}
          active={view === 'profile'}
          onClick={() => setViewFunction('profile')}
          style={{ ...cardStyles, ...horizontalCardStyles, top: 25 }}
        />
      </div> */}
    </>
  );
};

export default Index;
