import React from 'react';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import InvalidityMsg from 'components/InvalidityMsg';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  email: validValue;
  setEmail: (email: validValue) => void;
}

interface validValue {
  value: string;
  invalidity: string;
}

const Index: React.FC<Props> = ({
  open,
  onClose,
  onSave,
  email,
  setEmail,
}: Props) => {
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setEmail({ ...email, value });
  };

  return (
    <Modal open={open} setOpen={onClose}>
      <div className="modal" style={{ alignItems: 'flex-start', width: 450 }}>
        <div className="titleBigBackground" style={{ marginBottom: 5 }}>
          <h3>ADICIONAR JOGADOR</h3>
        </div>
        <div style={{ width: '100%', marginTop: 5 }}>
          <CustomInput
            label="Insira o e-mail"
            placeholder="Insira o e-mail do jogador a ser adicionado"
            error={email.invalidity}
            onChange={onChangeEmail}
            value={email.value}
          />

          <InvalidityMsg msg={email.invalidity} />

          <div style={{ marginTop: 10 }}>
            <CustomButton
              onClick={onSave}
              text="SALVAR"
              mode="outlined"
              small
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Index;
