import { useState, useEffect } from 'react';
import api from 'services/api';
import Cookies from 'js-cookie';
import { ContextProps } from 'context/authContext';

const useAuth = (): ContextProps => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get('sigma_token');
    if (token) {
      api.defaults.headers.Authorization = `Token ${token}`;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  const login = async (email: string, password: string): Promise<string> => {
    try {
      const response = await api.post('/profiles/authentication', {
        email,
        password,
      });
      const { token, id } = response?.data;
      Cookies.set('sigma_userid', id);
      Cookies.set('sigma_token', token);
      api.defaults.headers.Authorization = `Token ${token}`;
      setAuthenticated(true);
    } catch (error) {
      return error.response?.data.detail;
    }

    return '';
  };

  const logout = (): boolean => {
    setAuthenticated(false);
    Cookies.remove('sigma_token');
    Cookies.remove('sigma_userid');
    api.defaults.headers.Authorization = undefined;
    return false;
  };

  const checkAuth = (status: number): void => {
    if (status === 401) {
      logout();
    }
  };

  return { authenticated, loading, login, logout, checkAuth };
};

export default useAuth;
