import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import './styles.css';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const index: React.FC<Props> = ({ onChange, label }: Props) => {
  return (
    <TextField
      label={label}
      size="small"
      type="search"
      variant="outlined"
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search className="searchIcon" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default index;
