import React from 'react';

import './styles.css';

export interface PlayerDetailedInfo {
  beginDate: string;
  position: string;
  pendecies: number;
  deal: number;
  limit: number;
  money: number;
  makeUp: number;
  bank: number;
  totalProfit: number;
  teamProfit: number;
}
interface Props {
  detailedInfo: PlayerDetailedInfo;
}
const Index: React.FC<Props> = ({ detailedInfo }: Props) => {
  return (
    <div className="infoPlayerBoxContent">
      <div className="infoPlayerUnityContent">
        <b className="textContent">Data Início</b>
        <p className="textContent">
          {new Date(detailedInfo.beginDate).toLocaleDateString()}
        </p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Cargo</b>
        <p className="textContent">{detailedInfo.position}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Pendências</b>
        <p className="textContent">{detailedInfo.pendecies}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Deal</b>
        <p className="textContent">{detailedInfo.deal}%</p>
      </div>
      {/* <div className="infoPlayerUnityContent">
        <b className="textContent">Limite</b>
        <p className="textContent">{detailedInfo.limit}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Caixa</b>
        <p className="textContent">${detailedInfo.money}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">MakeUp</b>
        <p className="textContent">${detailedInfo.makeUp}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Banco</b>
        <p className="textContent">${detailedInfo.bank}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Lucro Total</b>
        <p className="textContent">${detailedInfo.totalProfit}</p>
      </div>
      <div className="infoPlayerUnityContent">
        <b className="textContent">Lucro Time</b>
        <p className="textContent">${detailedInfo.teamProfit}</p>
      </div> */}
    </div>
  );
};

export default Index;
