import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

import './styles.css';

interface Props extends ButtonProps {
  text: string;
}

const index: React.FC<Props> = (props) => {
  return (
    <Button className="signButton" onClick={props.onClick}>
      {props.text}
    </Button>
  );
};

export default index;
