import React, { useEffect, useState, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import Plot from 'react-plotly.js';
import api from 'services/api';
import swal from 'sweetalert';
import { Context } from 'context/authContext';
import Cookies from 'js-cookie';
import ChartHeader from '../Header';

interface IChartPoint {
  date: Date;
  profit: number;
}

interface Props {
  teamId: number | undefined;
  currentChart: string;
}

const Index: React.FC<Props> = ({ teamId, currentChart }: Props) => {
  const { checkAuth } = useContext(Context);
  const [chartPoints, setChartPoints] = useState<IChartPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDate = (profitObj: Record<string, string>): IChartPoint => ({
    date: new Date(profitObj.date),
    profit: parseInt(profitObj.profit, 10),
  });

  useEffect(() => {
    const token = Cookies.get('sigma_token');
    const config = teamId
      ? {
          headers: {
            Authorization: `Token ${token}`,
            TeamId: teamId,
          },
        }
      : {};

    api
      .get('/graphs/profit', config)
      .then((resp) => {
        setChartPoints(resp.data.map(getDate));
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
  }, [checkAuth, teamId]);

  return (
    <>
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 600"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="120" height="50" />
            <rect x="0" y="70" rx="10" ry="10" width="100%" height="500" />
          </ContentLoader>
        </div>
      ) : (
        <>
          <ChartHeader chart={currentChart} />
          <Plot
            data={[
              {
                name: 'profit',
                type: 'waterfall',
                x: chartPoints.map((x) => x.date),
                y: chartPoints.map((x) => x.profit),
                connector: { visible: false },
                decreasing: { marker: { color: '#f02828' } },
                increasing: { marker: { color: '#05f34c' } },
              },
            ]}
            layout={{
              xaxis: {
                type: 'date',
                linecolor: 'black',
                nticks: 10,
              },
              yaxis: {
                type: 'linear',
                tickprefix: '$',
                nticks: 12,
                showgrid: true,
                gridcolor: '#838383',
              },
              autosize: true,
              margin: { t: 20, l: 40, r: 40, b: 40 },
            }}
          />
        </>
      )}
    </>
  );
};

export default Index;
