import React, { useState, useEffect, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import CustomButton from 'components/CustomButton';
import CardFlex from 'components/CardFlex';
import Modal from 'components/Modal';
import CustomInput from 'components/CustomInput';
import InvalidityMsg from 'components/InvalidityMsg';
import api from 'services/api';
import swal from 'sweetalert';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Context } from 'context/authContext';
import Cookies from 'js-cookie';
import CustomHandModal from './CustomHandModal';

import './styles.css';

interface IMonthCoverage {
  name: string;
  pendings: string;
  coverage: number;
  registers: number;
  auditCoverage: number;
  auditPendingDays: string;
  sharkscopeCoverage: string | number;
}

interface IHeader {
  auditCoverage: number;
  coverage: number;
  pendings: number;
  registers: number;
  sharkscopeCoverage: number;
}

const Index: React.FC = () => {
  const { checkAuth } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [handModal, setHandModal] = useState<boolean>(false);
  const [handModalType, setHandModalType] = useState<string>('');

  const [currCoverageYear, setCurrCoverageYear] = useState<string>('');
  const [coverageYear, setCoverageYear] = useState<string[]>([]);
  const [headerCard, setHeaderCard] = useState<IHeader | undefined>();
  const [lista, setLista] = useState<Record<string, IMonthCoverage[]>>();

  const [url, setUrl] = useState({ value: '', invalidity: '' });
  const [password, setPassword] = useState({ value: '', invalidity: '' });
  const [file, setFile] = useState({
    value: new File([''], ''),
    invalidity: '',
  });

  useEffect(() => {
    api
      .get(`/transactions/coverage/${Cookies.get('sigma_userid')}`)
      .then((resp) => {
        const { body, header } = resp.data;
        const newCoverageYear = Object.keys(body);

        setHeaderCard(header);
        setLista(body);
        setCoverageYear(newCoverageYear);
        setCurrCoverageYear(newCoverageYear.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
  }, [checkAuth]);

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setUrl({ ...url, value });
  };

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;

    setPassword({ ...password, value });
  };

  const toggleAuditModal = (): void => {
    if (auditModal) {
      setUrl({ value: '', invalidity: '' });
      setPassword({ value: '', invalidity: '' });
    }
    setAuditModal(!auditModal);
  };

  const submitAudit = (): void => {
    const newUrl = url;
    const newPassword = password;
    newUrl.invalidity = url.value ? '' : 'Informe a url';
    newPassword.invalidity = password.value ? '' : 'Digite a senha';

    setUrl({ ...newUrl });
    setPassword({ ...newPassword });

    if (!newUrl.invalidity && !newPassword.invalidity) {
      api
        .post('/audits/', {
          audit_url: url.value,
          password: password.value,
        })
        .then(() => {
          swal('Pronto!', 'Auditoria enviada com sucesso!', 'success');
        })
        .catch((error) => {
          const msg = error.response?.data.detail;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
      toggleAuditModal();
    }
  };

  const customAuditModal = (): JSX.Element => {
    return (
      <Modal open={auditModal} setOpen={toggleAuditModal}>
        <div className="modal" style={{ alignItems: 'flex-start', width: 450 }}>
          <div className="titleBigBackground" style={{ marginBottom: 20 }}>
            <span>AUDITORIA POKERSTARS</span>
          </div>
          <span>Cole abaixo o link da auditoria recebida por email.</span>
          <div style={{ width: '100%', marginTop: 5 }}>
            <CustomInput
              label="URL"
              id="url"
              name="url"
              error={url.invalidity}
              onChange={onChangeUrl}
              value={url.value}
            />
            <InvalidityMsg msg={url.invalidity} />
            <CustomInput
              label="Senha"
              id="password"
              name="password"
              error={password.invalidity}
              onChange={onChangePassword}
              value={password.value}
              type="password"
            />
            <InvalidityMsg msg={password.invalidity} />
            <div style={{ marginTop: 10 }}>
              <CustomButton
                onClick={() => {
                  submitAudit();
                }}
                text="ENVIAR"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const changeFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    if (files) {
      const value = files[0];
      if (value) {
        setFile({ value, invalidity: '' });
      }
    }
  };

  const toggleHandModal = (): void => {
    if (handModal) {
      setFile({ value: new File([''], ''), invalidity: '' });
    }
    setHandModal(!handModal);
  };

  const submitHand = async (): Promise<void> => {
    const newFile = file;
    newFile.invalidity = file.value.name ? '' : 'Selecione um arquivo';

    setFile({ ...newFile });

    if (!newFile.invalidity) {
      const handhistory = new FormData();
      handhistory.append('handhistory', file.value, file.value.name);
      await api
        .post('/handhistories/', handhistory, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          swal('Pronto!', 'Arquivo enviado com sucesso!', 'success');
        })
        .catch((error) => {
          const msg = error.response?.data.detail;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
      toggleHandModal();
    }
  };

  return (
    <>
      {customAuditModal()}
      <CustomHandModal
        type={handModalType}
        handModal={handModal}
        toggleHandModal={toggleHandModal}
        changeFile={(e) => changeFile(e)}
        submitHand={submitHand}
        file={file.value.name.toString()}
        error={file.invalidity}
      />

      <div className="containerManageList">
        {isLoading ? (
          <div style={{ width: '100%', marginTop: 5 }}>
            <ContentLoader
              viewBox="0 0 1000 550"
              backgroundColor="#e5e5e5"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="10" ry="10" width="100" height="30" />
              <rect x="0" y="50" rx="10" ry="10" width="100%" height="100" />
              <rect x="0" y="170" rx="10" ry="10" width="120" height="40" />
              <rect x="130" y="170" rx="10" ry="10" width="120" height="40" />
              <rect x="260" y="170" rx="10" ry="10" width="120" height="40" />
              <rect x="0" y="230" rx="3" ry="3" width="100%" height="5" />
              <rect x="0" y="250" rx="10" ry="10" width="100%" height="25" />
              <rect x="0" y="300" rx="10" ry="10" width="100%" height="25" />
              <rect x="0" y="350" rx="10" ry="10" width="100%" height="25" />
              <rect x="0" y="400" rx="10" ry="10" width="100%" height="25" />
              <rect x="0" y="450" rx="10" ry="10" width="100%" height="25" />
              <rect x="0" y="500" rx="10" ry="10" width="100%" height="25" />
            </ContentLoader>
          </div>
        ) : (
          <>
            <div className="headerView">
              <div className="titleBigBackground">
                <span>COBERTURA</span>
              </div>
            </div>

            <div className="overview" style={{ marginTop: 20 }}>
              <div>
                <span className="fontTitle">Registros</span>
                <span>{headerCard?.registers}</span>
              </div>
              <div>
                <span className="fontTitle">Cobertura</span>
                <span>
                  <NumberFormat
                    value={headerCard?.coverage}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                </span>
              </div>
              <div>
                <span className="fontTitle">Cobertura Audits</span>
                <span>
                  <NumberFormat
                    value={headerCard?.auditCoverage}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                </span>
              </div>
              <div>
                <span className="fontTitle">Cobertura Sharkscope</span>
                <span>
                  <NumberFormat
                    value={headerCard?.sharkscopeCoverage}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                </span>
              </div>
              <div>
                <span className="fontTitle">Pendências</span>
                <span>{headerCard?.pendings}</span>
              </div>
            </div>

            <div className="headerButtons">
              <div className="searchPlayerContainer">
                <CustomButton
                  text="AUDITORIA"
                  style={{ height: 'auto' }}
                  mode="outlined"
                  onClick={() => toggleAuditModal()}
                >
                  <FileUploadRoundedIcon style={{ marginLeft: 5 }} />
                </CustomButton>
              </div>
              <div className="searchPlayerContainer">
                <CustomButton
                  text="ENVIAR MÃOS"
                  style={{ height: 'auto' }}
                  mode="outlined"
                  onClick={() => {
                    toggleHandModal();
                    setHandModalType('upload');
                  }}
                >
                  <FileUploadRoundedIcon style={{ marginLeft: 5 }} />
                </CustomButton>
              </div>
              <div className="searchPlayerContainer">
                <CustomButton
                  text="BAIXAR MÃOS"
                  style={{ height: 'auto' }}
                  mode="outlined"
                  onClick={() => {
                    toggleHandModal();
                    setHandModalType('download');
                  }}
                >
                  <FileDownloadRoundedIcon style={{ marginLeft: 5 }} />
                </CustomButton>
              </div>
            </div>

            <hr className="lineDiv" style={{ marginTop: 20 }} />
            <div style={{ display: 'flex', gap: 15, marginTop: 20 }}>
              <div>
                <FormControl variant="outlined">
                  <Select
                    className="selectTeamContainer"
                    value={currCoverageYear}
                    onChange={(e) =>
                      setCurrCoverageYear(e.target.value as string)
                    }
                  >
                    {coverageYear.map((item) => (
                      <MenuItem key={item} value={item}>
                        <h3 style={{ fontSize: 15 }}>{item}</h3>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <hr className="lineGray" />
            </div>
            <CardFlex title>
              <div>
                <span>Mês</span>
              </div>
              <div>
                <span>Cobertura</span>
              </div>
              <div>
                <span>Registros</span>
              </div>
              <div>
                <span>Cobertura Audit</span>
              </div>
              <div>
                <span>Dias Pendentes</span>
              </div>
              <div style={{ flex: 2 }}>
                <span>Cobertura SharkScope</span>
              </div>
              <div>
                <span>Pendências</span>
              </div>
            </CardFlex>
            <div className="manageList">
              {lista &&
                Object.entries(lista).map(([year, months]) => (
                  <>
                    {year === currCoverageYear && (
                      <>
                        {months.map((month) => (
                          <CardFlex key={month.name}>
                            <div>
                              <span>{month.name}</span>
                            </div>
                            <div>
                              <span>
                                <NumberFormat
                                  value={month.coverage}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  suffix="%"
                                  decimalScale={2}
                                  fixedDecimalScale
                                  displayType="text"
                                />
                              </span>
                            </div>
                            <div>
                              <span>{month.registers}</span>
                            </div>
                            <div>
                              <span>
                                <NumberFormat
                                  value={month.auditCoverage}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  suffix="%"
                                  decimalScale={2}
                                  fixedDecimalScale
                                  displayType="text"
                                />
                              </span>
                            </div>
                            <div>
                              <span title={month.auditPendingDays as string}>
                                {month.auditPendingDays}
                              </span>
                            </div>
                            <div style={{ flex: 2 }}>
                              <span>
                                {month.sharkscopeCoverage !== '-' ? (
                                  <NumberFormat
                                    value={month.sharkscopeCoverage}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    suffix="%"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType="text"
                                  />
                                ) : (
                                  month.sharkscopeCoverage
                                )}
                              </span>
                            </div>
                            <div>
                              <span>{month.pendings}</span>
                            </div>
                          </CardFlex>
                        ))}
                      </>
                    )}
                  </>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Index;
