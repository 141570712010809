import React, { ButtonHTMLAttributes } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  close?: boolean;
}

const Index: React.FC<Props> = ({ small, close, onClick }: Props) => {
  return (
    <IconButton onClick={onClick} className="deleteButton">
      {close ? (
        <CloseIcon style={{ fontSize: small ? 20 : 24 }} />
      ) : (
        <DeleteForeverIcon style={{ fontSize: small ? 20 : 24 }} />
      )}
    </IconButton>
  );
};

Index.defaultProps = {
  small: false,
  close: false,
};

export default Index;
