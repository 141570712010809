import React, { ButtonHTMLAttributes, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import './styles.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  changePage: (n: number) => void;
  page: number;
  max: number;
}

const Index: React.FC<Props> = ({ changePage, page, max }: Props) => {
  const [min, setMin] = useState(1);

  useEffect(() => {
    const setList = (): void => {
      if (page > min + 4) {
        setMin(min + 1);
      } else if (page < min) {
        setMin(min - 1);
      }
    };
    setList();
  }, [page]);

  const pages = (): number[] => {
    const result = [];
    const range = min + 4;
    const finalPage = range < max ? range : max;
    for (let i = min; i <= finalPage; i += 1) {
      result.push(i);
    }
    return result;
  };

  const list = pages();

  return (
    <div className="pagingButtons">
      <Button
        onClick={() => changePage(page - 1)}
        className={
          page === 1
            ? 'prevButton outlinedPagingButtons'
            : 'prevButton bluePagingButtons'
        }
      >
        Anterior
      </Button>
      {list.map((number) => (
        <Button
          onClick={() => changePage(number)}
          key={number}
          className={
            number === page
              ? 'currentNumberPagingButtons outlinedPagingButtons'
              : 'numberPagingButtons outlinedPagingButtons'
          }
        >
          {number}
        </Button>
      ))}
      <Button
        onClick={() => changePage(page + 1)}
        className={
          page === max
            ? 'nextButton outlinedPagingButtons'
            : 'nextButton bluePagingButtons'
        }
      >
        Próximo
      </Button>
    </div>
  );
};

export default Index;
