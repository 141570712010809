import React from 'react';
import './styles.css';

interface Props {
  children: JSX.Element | JSX.Element[] | (false | JSX.Element)[];
  type?: 'flex' | 'around' | 'between';
  dense?: boolean;
  title?: boolean;
}

const Index: React.FC<Props> = ({ children, type, dense, title }: Props) => {
  const customClass = (): string => {
    let res1 = 'defaultCardFlex';
    let res2 = '';
    if (type === 'around') res1 = 'aroundCardFlex';
    else if (type === 'between') res1 = 'betweenCardFlex';
    if (title) res2 = 'titleBackgroundCardFlex';
    return `${res1}  ${res2}`;
  };
  return (
    <div
      className={`cardFlex ${customClass()}`}
      style={{ padding: dense ? '12px 40px' : '12px 20px' }}
    >
      {children}
    </div>
  );
};

Index.defaultProps = {
  type: 'flex',
  dense: false,
  title: false,
};

export default Index;
