import React, { useContext, useState } from 'react';
import './styles.css';
import SignBox from 'components/SignBox';
import swal from 'sweetalert';
import SignInput from 'components/SignInput';
import InvalidityMsg from 'components/InvalidityMsg';
import { validatePassword } from 'util/validation';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import api from 'services/api';
import { Context } from 'context/authContext';

interface MatchParams {
  uidb64: string;
  token: string;
}

type Props = RouteComponentProps<MatchParams>;

const ChangePassword: React.FC<Props> = ({ match }: Props) => {
  const { checkAuth } = useContext(Context);

  const [repeatPassword, setRepeatPassword] = useState({
    value: '',
    invalidity: '',
  });
  const [password, setPassword] = useState({ value: '', invalidity: '' });
  const history = useHistory();

  const changeRepeatPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;

    setRepeatPassword({ ...repeatPassword, value });
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setPassword({ ...password, value });
  };

  const handleSubmit = (): void => {
    const invalidityPassword = validatePassword(password.value);
    setPassword({ ...password, invalidity: invalidityPassword });

    if (password.value !== repeatPassword.value) {
      setPassword({ value: '', invalidity: 'As senhas devem ser idênticas' });
      setRepeatPassword({
        value: '',
        invalidity: 'As senhas devem ser idênticas',
      });
    }

    if (!password.invalidity || !repeatPassword.invalidity) {
      api
        .post(
          `profiles/password/reset/${match.params.uidb64}/${match.params.token}`,
          { new_password: password.value }
        )
        .then(() => {
          swal('Pronto!', 'Senha atualizada com sucesso.', 'success');
          history.push('/');
        })
        .catch((error) => {
          const msg = error.response?.data.detail || error.message;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });

          history.push('/');
        });
    }
  };

  return (
    <SignBox
      question={
        <span>
          Ainda não tem uma conta?<a href="/signup"> Cadastre-se!</a>
        </span>
      }
      textButton="Alterar Senha"
      title="Nova Senha"
      handleSubmit={handleSubmit}
    >
      <SignInput
        label="Nova senha"
        id="password"
        name="password"
        type="password"
        error={password.invalidity}
        onChange={changePassword}
        value={password.value}
      />
      <InvalidityMsg msg={password.invalidity} />
      <SignInput
        label="Repetir senha"
        placeholder="Repita a nova senha"
        id="repeatPassword"
        name="repeatPassword"
        type="password"
        error={repeatPassword.invalidity}
        onChange={changeRepeatPassword}
        value={repeatPassword.value}
      />
      <InvalidityMsg msg={repeatPassword.invalidity} />
      <div className="loginForgotPassword">
        <a href="/">Voltar para login.</a>
      </div>
    </SignBox>
  );
};

export default ChangePassword;
