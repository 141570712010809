import React from 'react';
import { CardViewType } from 'components/ViewCards';
import ViewProfile from './ViewProfile';
import Tournaments from './Tournaments';
import ViewPlayers from './ViewPlayers';
import Database from './Database';
import Manage from './Manage';
import Makeup from './Makeup';
import './styles.css';

interface Props {
  type: CardViewType;
  dashboardType: string;
  teamId: number | undefined;
}

// const COMPONENT_MAP: Record<string, React.FC> = {
//   profile: ViewProfile,
//   tournaments: Tournaments,
//   manage: Manage,
//   players: ViewPlayers,
//   // makeup: Makeup,
//   // TODO:
//   database: Database,
//   stakes: Tournaments,
//   audits: Tournaments,
// };

const Index: React.FC<Props> = ({ type, dashboardType, teamId }: Props) => {
  // const Component = COMPONENT_MAP[type];

  const selectComponent = (cardType: CardViewType): JSX.Element => {
    switch (cardType) {
      case 'profile':
        return <ViewProfile />;
      case 'tournaments' || 'stakes' || 'audits':
        return <Tournaments teamId={teamId} />;
      case 'manage':
        return <Manage />;
      case 'players':
        return <ViewPlayers />;
      case 'makeup':
        return <Makeup dashboardType={dashboardType} />;
      case 'database':
        return <Database />;
      default:
        return <></>;
    }
  };

  return <div className="contentViewDashboard">{selectComponent(type)}</div>;
};

export default Index;
