import React, { InputHTMLAttributes } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './styles.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  multiple?: boolean;
  onChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
}

const Index: React.FC<Props> = ({
  label,
  error,
  multiple,
  onChange,
  value,
  defaultValue,
  children,
}: Props) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      className="customFormControl"
      error={error !== ''}
    >
      <InputLabel style={{ top: 0 }} id="demo-simple-select-outlined-label">
        {label}
      </InputLabel>
      <Select
        className="customSelect"
        value={value}
        onChange={onChange}
        multiple={multiple}
        label={label}
        defaultValue={defaultValue}
      >
        {children}
      </Select>
    </FormControl>
  );
};

Index.defaultProps = {
  error: '',
  multiple: false,
};

export default Index;
