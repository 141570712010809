export const convertDate = (date: string): string => {
  const d = date.substring(0, 10).split('-');

  return `${d[2]}/${d[1]}/${d[0]}`;
};

export const prettyDate = (str: string): string => {
  return new Date(str).toLocaleDateString('pt-PT');
};

export const prettyFullDate = (str: string): string => {
  return new Date(str).toLocaleString('pt-PT');
};
