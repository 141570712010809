import React, { useState, useContext } from 'react';
import './styles.css';
import SignBox from 'components/SignBox';
import SignInput from 'components/SignInput';
import InvalidityMsg from 'components/InvalidityMsg';
import { validateEmail, validatePassword } from 'util/validation';
import { useHistory } from 'react-router-dom';

import { Context } from '../../context/authContext';

const Login: React.FC = () => {
  const history = useHistory();
  const { login } = useContext(Context);

  const [email, setEmail] = useState({ value: '', invalidity: '' });
  const [password, setPassword] = useState({ value: '', invalidity: '' });
  const [errorMsg, setErrorMsg] = useState('');

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setEmail({ ...email, value });
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setPassword({ ...password, value });
  };

  const handleSubmit = async (): Promise<void> => {
    const invalidityEmail = validateEmail(email.value);
    const invalidityPassowrd = validatePassword(password.value);

    setEmail({ ...email, invalidity: invalidityEmail });
    setPassword({ ...password, invalidity: invalidityPassowrd });

    if (!invalidityEmail && !invalidityPassowrd) {
      const res = await login(email.value, password.value);
      if (res === '') {
        history.push('/dashboard');
      } else {
        setErrorMsg(res);
      }
    }
  };

  return (
    <SignBox
      question={
        <span>
          Ainda não tem uma conta?<a href="/signup"> Cadastre-se!</a>
        </span>
      }
      textButton="Entrar"
      title="Login"
      handleSubmit={handleSubmit}
    >
      <SignInput
        label="Email"
        id="email"
        name="email"
        type="email"
        error={email.invalidity}
        onChange={changeEmail}
        value={email.value}
      />
      <InvalidityMsg msg={email.invalidity} />
      <SignInput
        label="Senha"
        id="password"
        name="password"
        type="password"
        error={password.invalidity}
        onChange={changePassword}
        value={password.value}
      />
      <InvalidityMsg msg={password.invalidity} />
      <InvalidityMsg msg={errorMsg} />
      <div className="loginForgotPassword">
        <a href="/forgot-password">Esqueceu sua senha?</a>
      </div>
    </SignBox>
  );
};

export default Login;
