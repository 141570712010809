import React, { useContext, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import MonthRow from 'components/InfoPlayerBox/ModalCoverage/MonthRow';
import CardFlex from 'components/CardFlex';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import api from 'services/api';
import swal from 'sweetalert';
import { Context } from 'context/authContext';

import './styles.css';
import Cookies from 'js-cookie';

interface IMonthCoverage {
  name: string;
  pendings: string;
  coverage: number;
  registers: number;
  auditCoverage: number;
  auditPendingDays: string;
  sharkscopeCoverage: string | number;
}

interface IHeader {
  auditCoverage: number;
  coverage: number;
  pendings: number;
  registers: number;
  sharkscopeCoverage: number;
}

interface Props {
  teamId: number;
  profileId: number;
  playerName: string;
  open: boolean;
  // coverageData: Record<string, unknown>; // TODO: change to typed data
  onClose: () => void;
}

const Index: React.FC<Props> = ({
  teamId,
  profileId,
  playerName,
  open,
  onClose,
}: Props) => {
  const { checkAuth } = useContext(Context);
  const [currCoverageYear, setCurrCoverageYear] = useState<string>('');
  const [coverageYear, setCoverageYear] = useState<string[]>([]);
  const [lista, setLista] = useState<Record<string, IMonthCoverage[]>>();
  const [headerCard, setHeaderCard] = useState<IHeader | undefined>();

  useEffect(() => {
    if (profileId && teamId) {
      const token = Cookies.get('sigma_token');
      const config = teamId
        ? {
            headers: {
              Authorization: `Token ${token}`,
              TeamId: teamId,
            },
          }
        : {};
      api
        .get(`/transactions/coverage/${profileId}`, config)
        .then((resp) => {
          const { body, header } = resp.data;
          const newCoverageYear = Object.keys(body);
          setHeaderCard(header);
          setLista(body);
          setCoverageYear(newCoverageYear);
          setCurrCoverageYear(newCoverageYear.slice(-1)[0]);
        })
        .catch((error) => {
          const msg = error.response?.data.detail;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
    }
  }, [checkAuth, profileId, teamId]);

  return (
    <Modal open={open} setOpen={onClose}>
      <div className="modalContainer" style={{ width: '70vw' }}>
        <header>
          <p className="headerPlayerName">{playerName}</p>
          <div className="overview" style={{ marginTop: 20 }}>
            <div>
              <span className="fontTitle">Registros</span>
              <span>{headerCard?.registers}</span>
            </div>
            <div>
              <span className="fontTitle">Cobertura</span>
              <span>
                <NumberFormat
                  value={headerCard?.coverage}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Cobertura Audits</span>
              <span>
                <NumberFormat
                  value={headerCard?.auditCoverage}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Cobertura Sharkscope</span>
              <span>
                <NumberFormat
                  value={headerCard?.sharkscopeCoverage}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Pendências</span>
              <span>{headerCard?.pendings}</span>
            </div>
          </div>
        </header>
        <div style={{ display: 'flex', gap: 15, marginTop: 20 }}>
          <div>
            <FormControl variant="outlined">
              <Select
                className="selectTeamContainer"
                value={currCoverageYear}
                onChange={(e) => setCurrCoverageYear(e.target.value as string)}
              >
                {coverageYear.map((item) => (
                  <MenuItem key={item} value={item}>
                    <h3 style={{ fontSize: 15 }}>{item}</h3>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <hr className="lineGray" />
        </div>
        <CardFlex title>
          <div>
            <span>Mês</span>
          </div>
          <div>
            <span>Cobertura</span>
          </div>
          <div>
            <span>Registros</span>
          </div>
          <div>
            <span>Cobertura Audit</span>
          </div>
          <div>
            <span>Dias Pendentes</span>
          </div>
          <div style={{ flex: 2 }}>
            <span>Cobertura SharkScope</span>
          </div>
          <div>
            <span>Pendências</span>
          </div>
        </CardFlex>
        <div style={{ maxHeight: '50vh', overflow: 'auto', padding: '8px' }}>
          {lista &&
            Object.entries(lista).map(([year, months]) => (
              <>
                {year === currCoverageYear && (
                  <>
                    {months.map((month) => (
                      <CardFlex key={month.name}>
                        <div>
                          <span>{month.name}</span>
                        </div>
                        <div>
                          <span>
                            <NumberFormat
                              value={month.coverage}
                              thousandSeparator="."
                              decimalSeparator=","
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale
                              displayType="text"
                            />
                          </span>
                        </div>
                        <div>
                          <span>{month.registers}</span>
                        </div>
                        <div>
                          <span>
                            <NumberFormat
                              value={month.auditCoverage}
                              thousandSeparator="."
                              decimalSeparator=","
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale
                              displayType="text"
                            />
                          </span>
                        </div>
                        <div>
                          <span title={month.auditPendingDays as string}>
                            {month.auditPendingDays}
                          </span>
                        </div>
                        <div style={{ flex: 2 }}>
                          <span>
                            {month.sharkscopeCoverage !== '-' ? (
                              <NumberFormat
                                value={month.sharkscopeCoverage}
                                thousandSeparator="."
                                decimalSeparator=","
                                suffix="%"
                                decimalScale={2}
                                fixedDecimalScale
                                displayType="text"
                              />
                            ) : (
                              month.sharkscopeCoverage
                            )}
                          </span>
                        </div>
                        <div>
                          <span>{month.pendings}</span>
                        </div>
                      </CardFlex>
                    ))}
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default Index;
