/* eslint-disable no-console */
// TODO: MAKE DATABASE SCREEN
// until now, this is just a copy of tournament screen
import React, { useState } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomButton from 'components/CustomButton';
import ExpandCardFlex from 'components/ExpandCardFlex';
import CardFlex from 'components/CardFlex';
import PagingButtons from 'components/PagingButtons';
import './styles.css';

const Index: React.FC = () => {
  const [styles, setStyles] = useState(true);
  const [page, setPage] = useState(1);
  const [tournaments, setTournaments] = useState([
    {
      sala: 'PS',
      data: '04/10/2020',
      conta: 'ApelidoLongo',
      jogo: 'Hold`em',
      nome: 'Stadium Series Freezeout Grand Final',
      buyIn: '$55,00',
      lucro: '$210,00',
      moeda: 'USD',
      id: '3018148967',
      check: false,
    },
    {
      sala: 'PS',
      data: '04/10/2020',
      conta: 'ApelidoLongo',
      jogo: 'Hold`em',
      nome: '30/30 30 Special €30 €125000 Gtd',
      buyIn: '$55,00',
      lucro: '-$30,00',
      moeda: 'USD',
      id: '3018148968',
      check: false,
    },
    {
      sala: 'PS',
      data: '04/10/2020',
      conta: 'ApelidoLongo',
      jogo: 'Hold`em',
      nome: 'Stadium Series Freezeout Grand Final',
      buyIn: '$55,00',
      lucro: '$210,00',
      moeda: 'USD',
      id: '3018148969',
      check: false,
    },
  ]);
  const [check, setCheck] = useState(false);

  const changeCheckAll = (newCheck: boolean): void => {
    let list = tournaments;
    list = list.map((tournament) => {
      const newTournament = tournament;
      newTournament.check = newCheck;
      return newTournament;
    });

    setCheck(newCheck);
    setTournaments(list);
  };

  const changeCheck = (newCheck: boolean, id: string): void => {
    let list = tournaments;
    list = list.map((tournament) => {
      const newTournament = tournament;
      if (newTournament.id === id) {
        newTournament.check = newCheck;
      }
      return newTournament;
    });

    setTournaments(list);
  };

  return (
    <div className="containerTournamentsList">
      <div className="headerView">
        <CustomButton
          text="TORNEIOS"
          mode={styles ? 'primary' : 'outlined'}
          onClick={() => setStyles(true)}
        />
        <CustomButton
          text="TRANSAÇÕES"
          mode={styles ? 'outlined' : 'primary'}
          onClick={() => setStyles(false)}
        />
      </div>
      <div className="overview" style={{ marginTop: 20 }}>
        <div>
          <span className="fontTitle">Salas</span>
          <span>Todas</span>
        </div>
        <div>
          <span className="fontTitle">Total</span>
          <span>28.328</span>
        </div>
        <div>
          <span className="fontTitle">ROI</span>
          <span>24,94%</span>
        </div>
        <div>
          <span className="fontTitle">Média</span>
          <span>$57,28</span>
        </div>
        <div>
          <span className="fontTitle">Buy-in</span>
          <span>$337.108,03</span>
        </div>
        <div>
          <span className="fontTitle">Prize</span>
          <span>$431.281,02</span>
        </div>
        <div>
          <span className="fontTitle">Lucro</span>
          <span>$94.172,99</span>
        </div>
      </div>
      <div className="headerView" style={{ marginTop: 20 }}>
        <CustomButton
          text="AÇÕES EM MASSA"
          small
          onClick={() => console.log('ok')}
        />
        <CustomButton
          text="BAIXAR"
          small
          mode="gray"
          onClick={() => console.log('ok')}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <CustomButton text="FILTRAR" small onClick={() => console.log('ok')} />
      </div>
      <hr className="lineDiv" style={{ marginTop: 20 }} />
      <CardFlex title>
        <div style={{ display: 'flex', flex: 'none' }}>
          <CustomCheckbox
            checked={check}
            onClick={() => changeCheckAll(!check)}
          />
        </div>
        <div className="fontTitle">
          <span>Sala</span>
        </div>
        <div className="fontTitle mediumItemTournamentsList">
          <span>Data</span>
        </div>
        <div className="fontTitle mediumItemTournamentsList">
          <span>Conta</span>
        </div>
        <div className="fontTitle mediumItemTournamentsList">
          <span>Jogo</span>
        </div>
        <div className="fontTitle bigItemTournamentsList">
          <span>Nome</span>
        </div>
        <div className="fontTitle">
          <span>Buy-in</span>
        </div>
        <div className="fontTitle">
          <span>Lucro</span>
        </div>
        <div className="fontTitle">
          <span>Moeda</span>
        </div>
        <div className="fontTitle mediumItemTournamentsList">
          <span>ID</span>
        </div>
      </CardFlex>
      <div className="tournamentsList">
        {tournaments.map((tournament) => (
          <ExpandCardFlex
            key={tournament.id}
            header={
              <>
                <div style={{ flex: 'none' }}>
                  <CustomCheckbox
                    checked={tournament.check}
                    onClick={(e) => {
                      changeCheck(!tournament.check, tournament.id);
                      e.stopPropagation();
                    }}
                  />
                </div>
                <div>
                  <span>{tournament.nome}</span>
                </div>
              </>
            }
            details={
              <>
                <div>
                  <div>
                    <span>Nome: NomeMuitoLongo</span>
                    <span>Sala: {tournament.sala}</span>
                    <span>Conta: {tournament.conta}</span>
                    <span>Limite: MID Stakes Crusher</span>
                    <span>Número de mãos: 254</span>
                  </div>
                  <div>
                    <span>ID: {tournament.id}</span>
                    <span>Incio: {tournament.data}</span>
                    <span>Jogo: {tournament.jogo}</span>
                    <span>Velocidade: Normal</span>
                    <span>Estrutura: RE, 8Max</span>
                  </div>
                  <div>
                    <span>Moeda: {tournament.moeda}</span>
                    <span>Buy-in: ${tournament.buyIn}</span>
                    <span>Garantido: $1.000.000</span>
                  </div>
                  <div>
                    <span>Posição: 928/5218</span>
                    <span>Premiação: $250</span>
                    <span>Entradas: 2</span>
                    <span>Lucro: $200</span>
                  </div>
                </div>
                <div style={{ gap: 0 }}>
                  <div className="titleMediumBackground">
                    <span>LINHA DO TEMPO</span>
                  </div>
                  <hr className="lineGray" />
                </div>
                <div className="detailsCardTournamentsList">
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Ação</span>
                    <span>Registro</span>
                    <span>Rebuy</span>
                    <span>Reentrada</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Blinds</span>
                    <span>62.50bb</span>
                    <span>37.71bb</span>
                    <span>31.25bb</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      Horário de Registro
                    </span>
                    <span>04/10/2020 16:41:00</span>
                    <span>04/10/2020 16:41:00</span>
                    <span>04/10/2020 16:41:00</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Tempo</span>
                    <span>00:05:05</span>
                    <span>00:18:12</span>
                    <span>00:42:11</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>ID Mão</span>
                    <span>218046995914</span>
                    <span>218046995914</span>
                    <span>218046995914</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>ID Mesa</span>
                    <span>251819513</span>
                    <span>251819513</span>
                    <span>251819513</span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <hr className="lineGray" />
                </div>
                <div style={{ justifyContent: 'flex-start' }}>
                  <CustomButton
                    text="HANDHISTORY"
                    small
                    mode="gray"
                    onClick={() => console.log('ok')}
                  />
                  <CustomButton
                    text="SHARKSCOPE"
                    small
                    mode="gray"
                    onClick={() => console.log('ok')}
                  />
                </div>
              </>
            }
          >
            <div style={{ flex: 'none' }}>
              <CustomCheckbox
                checked={tournament.check}
                onClick={(e) => {
                  changeCheck(!tournament.check, tournament.id);
                  e.stopPropagation();
                }}
              />
            </div>
            <div>
              <span>{tournament.sala}</span>
            </div>
            <div className="mediumItemTournamentsList">
              <span>{tournament.data}</span>
            </div>
            <div className="mediumItemTournamentsList">
              <span>{tournament.conta}</span>
            </div>
            <div className="mediumItemTournamentsList">
              <span>{tournament.jogo}</span>
            </div>
            <div className="bigItemTournamentsList">
              <span>{tournament.nome}</span>
            </div>
            <div>
              <span>{tournament.buyIn}</span>
            </div>
            <div>
              <span>{tournament.lucro}</span>
            </div>
            <div>
              <span>{tournament.moeda}</span>
            </div>
            <div className="mediumItemTournamentsList">
              <span>{tournament.id}</span>
            </div>
          </ExpandCardFlex>
        ))}
      </div>
      <PagingButtons changePage={() => console.log('ok')} max={5} page={page} />
    </div>
  );
};

export default Index;
