import React, { Fragment, useState } from 'react';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';
import InputWithButton from 'components/InputWithButton';
import CustomCheckbox from 'components/CustomCheckbox';
import InvalidityMsg from 'components/InvalidityMsg';
import CustomDatePicker from 'components/CustomDatePicker';

import './styles.css';

interface Props {
  type: string;
  handModal: boolean;
  toggleHandModal: () => void;
  changeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitHand: () => void;
  file: string;
  error: string;
}

interface INetwork {
  check: boolean;
  name: string;
  hands: string;
  tournaments: string;
  size: string;
}

const Index: React.FC<Props> = ({
  type,
  handModal,
  toggleHandModal,
  changeFile,
  submitHand,
  file,
  error,
}: Props) => {
  const [check, setCheck] = useState<boolean>(false);
  const [beginDate, setBeginDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [networks, setNetworks] = useState<INetwork[]>([
    {
      name: 'PokerStars',
      hands: '123',
      tournaments: '21',
      size: '38MB',
      check: false,
    },
    {
      name: 'PartyPoker',
      hands: '100',
      tournaments: '21',
      size: '38MB',
      check: false,
    },
    {
      name: 'GGPoker',
      hands: '-',
      tournaments: '-',
      size: '-',
      check: false,
    },
  ]);

  const changeBeginDate = (date: Date | null): void => {
    setBeginDate(date);
  };

  const changeEndDate = (date: Date | null): void => {
    setEndDate(date);
  };

  const changeCheckAll = (newCheck: boolean): void => {
    let list = networks;
    list = list.map((network: INetwork) => {
      const newNetwork = network;
      newNetwork.check = newCheck;
      return newNetwork;
    });

    setCheck(newCheck);
    setNetworks(list);
  };

  const changeCheck = (newCheck: boolean, name: string): void => {
    let list = networks;
    list = list.map((network) => {
      const newNetwork = network;
      if (newNetwork.name === name) {
        newNetwork.check = newCheck;
      }
      return newNetwork;
    });

    setNetworks(list);
  };

  return (
    <Modal open={handModal} setOpen={toggleHandModal}>
      <div
        className="modal"
        style={{ alignItems: 'flex-start', width: 650, gap: 15 }}
      >
        <div style={{ display: 'flex', gap: 20, width: '100%' }}>
          <div className="titleBigBackground">
            <span>{type === 'upload' ? 'ENVIAR MÃOS' : 'BAIXAR MÃOS'}</span>
          </div>
          <hr className="lineGray" />
        </div>

        {type === 'upload' ? (
          <>
            <div style={{ width: 450 }}>
              <InputWithButton
                label="Escolher arquivos"
                changeFile={changeFile}
                submitHand={submitHand}
                file={file}
              />
              <InvalidityMsg msg={error} />
            </div>
          </>
        ) : (
          <>
            <div className="dateHandModal">
              <div>
                <CustomDatePicker
                  label="DATA INICIO"
                  value={beginDate}
                  onChange={changeBeginDate}
                />
              </div>
              <div>
                <CustomDatePicker
                  label="DATA FIM"
                  value={endDate}
                  onChange={changeEndDate}
                />
              </div>
            </div>
            <div className="networksHandModal">
              <div className="cardHandModal" style={{ padding: '0 5px' }}>
                <div style={{ display: 'flex', flex: 'none' }}>
                  <CustomCheckbox
                    checked={check}
                    onClick={() => changeCheckAll(!check)}
                  />
                </div>
                <div style={{ flex: 2 }}>
                  <p>Sala</p>
                </div>
                <div>
                  <p>N. Mãos</p>
                </div>
                <div>
                  <p>N. Torneios</p>
                </div>
                <div>
                  <p>Tamanho</p>
                </div>
              </div>
              <hr className="lineGray" style={{ width: '100%' }} />
              {networks.map((network) => {
                return (
                  <div className="cardHandModal">
                    <div style={{ flex: 'none' }}>
                      <CustomCheckbox
                        checked={network.check}
                        onClick={(e) => {
                          changeCheck(!network.check, network.name);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <div style={{ flex: 2 }}>
                      <b>{network.name}</b>
                    </div>
                    <div>
                      <p>{network.hands}</p>
                    </div>
                    <div>
                      <p>{network.tournaments}</p>
                    </div>
                    <div>
                      <p>{network.size}</p>
                    </div>
                  </div>
                );
              })}
              <hr className="lineGray" style={{ width: '100%' }} />
              <div className="cardHandModal" style={{ padding: '0 5px' }}>
                <div style={{ width: 16, flex: 'none' }} />
                <div style={{ flex: 2 }}>
                  <b>Total</b>
                </div>
                <div>
                  <p>223</p>
                </div>
                <div>
                  <p>42</p>
                </div>
                <div>
                  <p>76MB</p>
                </div>
              </div>
            </div>
            <hr className="lineDiv" />
            <CustomButton text="BAIXAR" />
          </>
        )}
      </div>
    </Modal>
  );
};

export default Index;
