import React, { useContext, useState } from 'react';
import './styles.css';
import SignBox from 'components/SignBox';
import SignInput from 'components/SignInput';
import InvalidityMsg from 'components/InvalidityMsg';
import { validateEmail } from 'util/validation';
import api from 'services/api';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { Context } from 'context/authContext';

const ForgotPassword: React.FC = () => {
  const { checkAuth } = useContext(Context);
  const [email, setEmail] = useState({ value: '', invalidity: '' });
  const history = useHistory();

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setEmail({ ...email, value });
  };

  const handleSubmit = (): void => {
    const invalidityEmail = validateEmail(email.value);

    setEmail({ ...email, invalidity: invalidityEmail });
    const sendEmailInfo = {
      title: 'Enviaremos um e-mail',
      details:
        'Se este e-mail estiver cadastrado, será enviado um link de verificação.',
      type: 'info',
    };
    if (!invalidityEmail) {
      history.push('/');
      api
        .post('/profiles/password/forget', { email: email.value })
        .then(() => {
          swal(sendEmailInfo.title, sendEmailInfo.details, sendEmailInfo.type);
        })
        .catch((error) => {
          if (error.response?.status !== 404) {
            const msg = error.response?.data.detail || error.message;
            swal('Algum erro aconteceu', msg, 'error').then(() => {
              checkAuth(error.response?.status);
            });
          }
        });

      swal(sendEmailInfo.title, sendEmailInfo.details, sendEmailInfo.type);
    }
  };

  return (
    <SignBox
      question={
        <span>
          Ainda não tem uma conta?<a href="/signup"> Cadastre-se!</a>
        </span>
      }
      textButton="Recuperar Senha"
      title="Esqueceu sua senha?"
      handleSubmit={handleSubmit}
    >
      <SignInput
        label="Email"
        id="email"
        name="email"
        type="email"
        error={email.invalidity}
        onChange={changeEmail}
        value={email.value}
      />
      <InvalidityMsg msg={email.invalidity} />
      <div className="loginForgotPassword">
        <a href="/">Voltar para login.</a>
      </div>
    </SignBox>
  );
};

export default ForgotPassword;
