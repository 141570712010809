import React from 'react';
import CustomButton from 'components/CustomButton';

import './styles.css';

interface Props {
  chart: string;
}

const index: React.FC<Props> = ({ chart }: Props) => {
  return (
    <header className="chartHeaderContainer">
      <CustomButton
        text="LUCRO"
        mode={chart === 'profit' ? 'primary' : 'outlined'}
      />
      {/* <CustomButton
        text="VOLUME"
        mode={chart === 'volume' ? 'primary' : 'outlined'}
      />
      <CustomButton
        text="INFRAÇÕES"
        mode={chart === 'infractions' ? 'primary' : 'outlined'}
      /> */}
    </header>
  );
};

export default index;
