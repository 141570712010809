export const validateName = (name: string, type = 'nome'): string => {
  const newName = name.trim();
  const re = /[^a-zà-ú]/gi;
  let response = '';

  if (re.test(newName)) response = `seu ${type} deve ter apenas letras`;
  else if (newName.length < 3)
    response = `Seu ${type} deve ter pelo menos 3 letras`;
  else if (newName.length > 100)
    response = `Seu ${type} deve ter no máximo 100 letras`;

  return response;
};

export const validateEmail = (email: string): string => {
  let response = 'Digite seu email';
  if (email.trim()) {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    response = re.test(email) ? '' : 'Email inválido';
  }

  return response;
};

export const validatePassword = (password: string): string => {
  let response = '';

  if (password.length < 6)
    response = 'Sua senha deve ter pelo menos 6 caracteres';
  else if (password.length > 20)
    response = 'Sua senha deve ter no máximo 20 caracteres';

  return response;
};

export const validateCoupleFields = (
  field1: unknown,
  field2: unknown
): string => {
  let response = '';
  if ((field1 || field2) && !(field1 && field2)) {
    response = 'Os dois campos devem ser preenchidos';
  }

  return response;
};
