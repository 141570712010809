import React, { ReactElement } from 'react';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';

interface Props {
  title: string;
  message?: string | ReactElement;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}

function Index({
  open,
  onConfirm,
  message,
  title,
  onClose,
}: Props): ReactElement {
  return (
    <Modal open={open} setOpen={onClose}>
      <div className="modal">
        <h4 style={{ marginBottom: 20 }}>{title}</h4>
        {message}
        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 15,
          }}
        >
          <CustomButton
            onClick={() => {
              onConfirm();
            }}
            text="CONFIRMAR"
            mode="green"
          />
          <CustomButton
            onClick={() => {
              onClose();
            }}
            text="CANCELAR"
            mode="red"
          />
        </div>
      </div>
    </Modal>
  );
}
Index.defaultProps = {
  message: '',
};
export default Index;
