import React, { useState, useEffect, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import SubContentViewProfile from 'components/SubContentViewProfile';
import GreyView from 'components/GreyView';
import CustomInput from 'components/CustomInput';
import InvalidityMsg from 'components/InvalidityMsg';
import api from 'services/api';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import { Context } from 'context/authContext';

import './styles.css';

const Account: React.FC = () => {
  const { checkAuth } = useContext(Context);
  const userId = Cookies.get('sigma_userid');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [userName, setUserName] = useState({
    originalValue: '',
    value: '',
    invalidity: '',
  });
  const [userLastName, setUserLastName] = useState({
    originalValue: '',
    value: '',
    invalidity: '',
  });
  const [userEmail, setUserEmail] = useState('');
  const [userPasswd, setUserPasswd] = useState({
    value: '',
    invalidity: '',
  });
  const [reload, setReload] = useState<boolean>(false);

  const greyViewStyle: React.CSSProperties = {
    margin: '15px 0px',
    display: 'flex',
    alignItems: 'center',
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`profiles/${userId}`)
      .then((response) => {
        const user = response.data;
        setUserName({
          ...userName,
          value: user.first_name,
          originalValue: user.first_name,
        });
        setUserLastName({
          ...userLastName,
          value: user.last_name,
          originalValue: user.last_name,
        });
        setUserEmail(user.email);
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, userId]);

  const toggleEditProfile = (): void => {
    setUserName({ ...userName, value: userName.originalValue });
    setUserLastName({ ...userLastName, value: userLastName.originalValue });
    setUserPasswd({ value: '', invalidity: '' });

    setEditProfile(!editProfile);
  };

  const saveNewProfile = (): void => {
    const newName = userName;
    const newLastName = userLastName;
    const newPasswd = userPasswd;

    newName.invalidity = newName.value ? '' : 'Selecione uma sala';
    newLastName.invalidity = newLastName.value ? '' : 'Digite o nome';
    newPasswd.invalidity = newPasswd.value ? '' : 'Digite a senha';

    setUserName({ ...newName });
    setUserLastName({ ...newLastName });
    setUserPasswd({ ...newPasswd });

    if (
      !newName.invalidity &&
      !newLastName.invalidity &&
      !newPasswd.invalidity
    ) {
      const body = {
        first_name: userName.value,
        last_name: userLastName.value,
        password: userPasswd.value,
      };
      api
        .patch(`profiles/${userId}`, body)
        .then(() => {
          setReload((_reload) => !_reload);
          swal('Dados atualizados com sucesso!', '', 'success');
          toggleEditProfile();
        })
        .catch((error) => {
          const msg = error.response?.data.detail || error.message;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  };

  const changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setUserName({ ...userName, value });
  };

  const changeLastName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setUserLastName({ ...userLastName, value });
  };

  const changePasswd = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setUserPasswd({ ...userPasswd, value });
  };

  return (
    <>
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 350"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100" height="40" />
            <rect x="0" y="50" rx="10" ry="10" width="32%" height="25" />
            <rect x="0" y="120" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="170" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="220" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="300" rx="10" ry="10" width="90" height="35" />
          </ContentLoader>
        </div>
      ) : (
        <SubContentViewProfile
          editMode={editProfile}
          onClick={() => toggleEditProfile()}
          mainFunction={() => saveNewProfile()}
          title="CONTA"
          description="Dados Pessoais."
          type="profile"
        >
          <GreyView style={greyViewStyle}>
            <div className="inlineView">
              <strong>Email: </strong>
              <span>{userEmail}</span>
            </div>
          </GreyView>
          <GreyView style={greyViewStyle}>
            <div className="inlineView">
              {editProfile ? (
                <div style={{ flex: 1 }}>
                  <CustomInput
                    id="name"
                    label="Nome"
                    value={userName.value}
                    onChange={changeName}
                    error={userName.invalidity}
                  />
                  <InvalidityMsg msg={userName.invalidity} />
                </div>
              ) : (
                <>
                  <strong>Nome: </strong>
                  <span>{userName.originalValue}</span>
                </>
              )}
            </div>
            <div className="inlineView">
              {editProfile ? (
                <div style={{ flex: 1 }}>
                  <CustomInput
                    id="email"
                    label="Sobrenome"
                    value={userLastName.value}
                    onChange={changeLastName}
                    error={userLastName.invalidity}
                  />
                  <InvalidityMsg msg={userLastName.invalidity} />
                </div>
              ) : (
                <>
                  <strong>Sobrenome: </strong>
                  <span>{userLastName.originalValue}</span>
                </>
              )}
            </div>
          </GreyView>
          <GreyView style={greyViewStyle}>
            <div className="inlineView">
              {editProfile ? (
                <div style={{ flex: 1 }}>
                  <CustomInput
                    id="passwd"
                    name="password"
                    type="password"
                    label="Senha"
                    value={userPasswd.value}
                    onChange={changePasswd}
                    error={userPasswd.invalidity}
                  />
                  <InvalidityMsg msg={userPasswd.invalidity} />
                </div>
              ) : (
                <>
                  <strong>Senha Atual: </strong>
                  <span>********</span>
                </>
              )}
            </div>
            <div className="inlineView" />
          </GreyView>
        </SubContentViewProfile>
      )}
    </>
  );
};

export default Account;
