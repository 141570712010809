import React, { useState } from 'react';
import './styles.css';
import swal from 'sweetalert';
import api from 'services/api';
import { useHistory } from 'react-router-dom';
import SignBox from '../../components/SignBox';
import SignInput from '../../components/SignInput';
import InvalidityMsg from '../../components/InvalidityMsg';
import {
  validateName,
  validateEmail,
  validatePassword,
} from '../../util/validation';

const Signup: React.FC = () => {
  const history = useHistory();

  const [name, setName] = useState({ value: '', invalidity: '' });
  const [lastName, setLastName] = useState({ value: '', invalidity: '' });
  const [email, setEmail] = useState({ value: '', invalidity: '' });
  const [password, setPassword] = useState({ value: '', invalidity: '' });
  const [errorMsg, setErrorMsg] = useState('');

  const changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setName({ ...name, value });
  };

  const changeLastName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setLastName({ ...lastName, value });
  };

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setEmail({ ...email, value });
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setPassword({ ...password, value });
  };

  const signup = async (): Promise<string> => {
    let msg = '';
    await api
      .post('/profiles/', {
        first_name: name.value,
        last_name: lastName.value,
        email: email.value,
        password: password.value,
      })
      .then(() => {
        swal(
          'Pronto!',
          'Enviamos um email para fazer a verificação do seu usuário',
          'success'
        );
        history.push('/');
      })
      .catch((error) => {
        msg = error.response?.data.detail;
      });

    return msg;
  };

  const handleSubmit = async (): Promise<void> => {
    const invalidityName = validateName(name.value);
    const invalidityLastName = validateName(lastName.value, 'sobrenome');
    const invalidityEmail = validateEmail(email.value);
    const invalidityPassword = validatePassword(password.value);

    setName({ ...name, invalidity: invalidityName });
    setLastName({ ...lastName, invalidity: invalidityLastName });
    setEmail({ ...email, invalidity: invalidityEmail });
    setPassword({ ...password, invalidity: invalidityPassword });

    if (
      !invalidityName &&
      !invalidityLastName &&
      !invalidityEmail &&
      !invalidityPassword
    ) {
      const msg = await signup();
      setErrorMsg(msg);
    }
  };

  return (
    <SignBox
      question={
        <span>
          Já possui uma conta?<a href="/"> Faça o login.</a>
        </span>
      }
      textButton="Cadastrar"
      title="Seja bem-vindo!"
      handleSubmit={handleSubmit}
    >
      <div className="signupText">
        <span>
          Só precisamos de algumas informações para criarmos sua conta. É
          rapidinho ;)
        </span>
      </div>
      <SignInput
        label="Nome"
        id="name"
        name="name"
        type="text"
        error={name.invalidity}
        onChange={changeName}
        value={name.value}
      />
      <InvalidityMsg msg={name.invalidity} />
      <SignInput
        label="Sobrenome"
        id="lastName"
        name="lastName"
        type="text"
        error={lastName.invalidity}
        onChange={changeLastName}
        value={lastName.value}
      />
      <InvalidityMsg msg={lastName.invalidity} />
      <SignInput
        label="Email"
        id="email"
        name="email"
        type="email"
        error={email.invalidity}
        onChange={changeEmail}
        value={email.value}
      />
      <InvalidityMsg msg={email.invalidity} />
      <SignInput
        label="Senha"
        id="password"
        name="password"
        type="password"
        error={password.invalidity}
        onChange={changePassword}
        value={password.value}
      />
      <InvalidityMsg msg={password.invalidity} />
      <InvalidityMsg msg={errorMsg} />
    </SignBox>
  );
};

export default Signup;
