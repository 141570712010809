/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import CustomButton from 'components/CustomButton';
import CardFlex from 'components/CardFlex';
import PagingButtons from 'components/PagingButtons';
import ExpandCardFlex from 'components/ExpandCardFlex';
import Cashier from 'components/ViewDashboard/Makeup/Cashier';
import FilterModal from 'components/ViewDashboard/Makeup/FilterModal';
import ManualModal from 'components/ViewDashboard/Makeup/ManualModal';
import FilterList from '@material-ui/icons/FilterList';
import AttachMoney from '@material-ui/icons/AttachMoney';

import TransactionDetail from './Detail';
import './styles.css';

export interface IHeader {
  avg: number;
  prize: number;
  profit: number;
  roi: number;
  staked: number;
  total: number;
}

export interface IHandhistory {
  id: number;
  hands: number;
  timeline: ITime[];
}

export interface ITournament {
  id: string;
  network: string;
  name: string;
  number?: string;
  date: string;
  type: string;
  amount: string;
  status: string;
  note: string;
  attachments: string[];
}

export interface ITime {
  action: string;
  blinds: number;
  datetime: string;
  elapsed_time: string;
  hand_id: number;
  table_id: number;
}

export interface IFilters {
  networks: string[];
  nicknames: string[];
  currencies: string[];
  games: string[];
  speeds: string[];
  structures: string[];
  formats: string[];
  prizes_structure: string[];
}

interface Props {
  dashboardType: string;
}

const Index: React.FC<Props> = ({ dashboardType }: Props) => {
  const [styles, setStyles] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showFilterList, setShowFilterList] = useState(false);
  const [showAttachMoney, setShowAttachMoney] = useState(false);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [headerCard, setHeaderCard] = useState<IHeader>({
    avg: 0,
    prize: 0,
    profit: 0,
    roi: 0,
    staked: 0,
    total: 0,
  });

  const [tournaments, setTournaments] = useState<ITournament[]>([
    {
      id: '1',
      network: 'PokerStars',
      name: 'Panchito Romero Miguel Junipero Francisco Quintero González III',
      date: '2021-06-13T15:45:00Z',
      type: 'Recarga',
      number: '500',
      amount: '800',
      status: 'Pendente',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      attachments: [
        'https://d3ltpfxjzvda6e.cloudfront.net/2020/09/29/pokerstars-old-lobby-cash-game-filters-sm.png',
        'https://vip-grinders.com.br/wp-content/uploads/2015/07/partypoker-lobby.png',
        'https://pokerindustrypro.com/site_media/media/uploads/news/ipoker-lobby-table-info.png',
        'https://www.yapoker.com/wp-content/uploads/2020/02/1.-MAIN-LOBBY-tp.jpg',
        'https://vip-grinders.com.br/wp-content/uploads/2016/06/888-Lobby.png',
      ],
    },
    {
      id: '2',
      network: 'PokerStars',
      name: 'Lorem Ipsum',
      date: '2021-06-13T15:45:00Z',
      type: 'Recarga',
      amount: '800',
      status: 'Pendente',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      attachments: [
        'https://d3ltpfxjzvda6e.cloudfront.net/2020/09/29/pokerstars-old-lobby-cash-game-filters-sm.png',
      ],
    },
    {
      id: '3',
      network: 'PokerStars',
      name: 'Lorem Ipsum',
      date: '2021-06-13T15:45:00Z',
      type: 'Recarga',
      amount: '800',
      status: 'Pendente',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      attachments: [
        'https://vip-grinders.com.br/wp-content/uploads/2015/07/partypoker-lobby.png',
        'https://pokerindustrypro.com/site_media/media/uploads/news/ipoker-lobby-table-info.png',
      ],
    },
    {
      id: '4',
      network: 'PokerStars',
      name: 'Lorem Ipsum',
      date: '2021-06-13T15:45:00Z',
      type: 'Recarga',
      amount: '800',
      status: 'Pendente',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      attachments: [
        'https://d3ltpfxjzvda6e.cloudfront.net/2020/09/29/pokerstars-old-lobby-cash-game-filters-sm.png',
        'https://vip-grinders.com.br/wp-content/uploads/2015/07/partypoker-lobby.png',
        'https://pokerindustrypro.com/site_media/media/uploads/news/ipoker-lobby-table-info.png',
      ],
    },
    {
      id: '5',
      network: 'PokerStars',
      name: 'Lorem Ipsum',
      date: '2021-06-13T15:45:00Z',
      type: 'Recarga',
      amount: '800',
      status: 'Fechado',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      attachments: [
        'https://pokerindustrypro.com/site_media/media/uploads/news/ipoker-lobby-table-info.png',
      ],
    },
  ]);
  const [filters, setFilters] = useState<IFilters>({
    networks: [],
    nicknames: [],
    currencies: [],
    games: [],
    speeds: [],
    structures: [],
    formats: [],
    prizes_structure: [],
  });

  const changePage = (newPage: number): void => {
    if (newPage < pagesTotal && newPage > 1) {
      setPage(newPage);
    }
  };

  const prettyDate = (str: string): string => {
    return new Date(str).toLocaleString();
  };

  return (
    <>
      {dashboardType === 'Pessoal' && <Cashier />}

      <div className="containerTournamentsList">
        <div className="headerView">
          <CustomButton
            text="ORDENS"
            mode={styles ? 'primary' : 'outlined'}
            onClick={() => setStyles(true)}
          />
        </div>
        <div className="overview" style={{ marginTop: 20 }}>
          <div>
            <span className="fontTitle">Contas</span>
            <span>93</span>
          </div>
          <div>
            <span className="fontTitle">Caixa</span>
            <span>$213.837,00</span>
          </div>
          <div>
            <span className="fontTitle">Total</span>
            <span>$681.281,00</span>
          </div>
          <div>
            <span className="fontTitle">Recargas</span>
            <span>$208.281,00</span>
          </div>
          <div>
            <span className="fontTitle">Envios</span>
            <span>$373.000,00</span>
          </div>
          <div>
            <span className="fontTitle">Saques</span>
            <span>$100.000,00</span>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <CustomButton
            text="FILTRAR"
            style={{ height: 'auto' }}
            mode="outlined"
            onClick={() => setShowFilterList(true)}
          >
            <FilterList style={{ marginLeft: 5 }} />
          </CustomButton>
          {dashboardType !== 'Pessoal' && (
            <CustomButton
              text="MANUAL"
              style={{ marginLeft: 15, height: 'auto' }}
              mode="outlined"
              onClick={() => setShowAttachMoney(true)}
            >
              <AttachMoney style={{ marginLeft: 5 }} />
            </CustomButton>
          )}
        </div>

        <hr className="lineDiv" style={{ marginTop: 20 }} />
        <CardFlex title>
          <div className="fontTitle">
            <span>Sala</span>
          </div>
          <div className="fontTitle bigItemTournaments">
            <span>Nome / Número</span>
          </div>
          <div className="fontTitle mediumItemTournaments">
            <span>Data Criação</span>
          </div>
          <div className="fontTitle">
            <span>Tipo</span>
          </div>
          <div className="fontTitle">
            <span>Quantidade</span>
          </div>
          <div className="fontTitle">
            <span>Status</span>
          </div>
          <div className="fontTitle mediumItemTournaments">
            <span>Ação</span>
          </div>
        </CardFlex>
        <div className="tournaments">
          {tournaments.map((tournament) => (
            <ExpandCardFlex
              key={tournament.id}
              header={
                <>
                  <div>
                    <span>{tournament.network}</span>
                  </div>
                  <div className="bigItemTournaments">
                    <span>{tournament.name}</span>
                  </div>
                  <div className="mediumItemTournaments">
                    <span>{prettyDate(tournament.date)}</span>
                  </div>
                  <div>
                    <span>{tournament.type}</span>
                  </div>
                  <div>
                    <span>{tournament.amount}</span>
                  </div>
                  <div>
                    <span>{tournament.status}</span>
                  </div>
                  <div
                    className="mediumItemTournaments"
                    style={{ display: 'flex', gap: 5 }}
                  >
                    {tournament.status === 'Pendente' ? (
                      <>
                        <CustomButton
                          text="ENVIAR"
                          small
                          mode="green"
                          onClick={(e) => {
                            console.log('ok');
                            e.stopPropagation();
                          }}
                        />
                        <CustomButton
                          text="CANCELAR"
                          small
                          mode="red"
                          onClick={(e) => {
                            console.log('ok');
                            e.stopPropagation();
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CustomButton
                          text="EDITAR"
                          small
                          mode="gray"
                          onClick={(e) => {
                            console.log('ok');
                            e.stopPropagation();
                          }}
                        />
                        <CustomButton
                          text="DETALHES"
                          small
                          mode="gray"
                          onClick={(e) => {
                            console.log('ok');
                            e.stopPropagation();
                          }}
                        />
                      </>
                    )}
                  </div>
                </>
              }
              details={
                <TransactionDetail
                  name={tournament.name}
                  number={tournament.number}
                  notes={tournament.note}
                  attachments={tournament.attachments}
                />
              }
            >
              <div>
                <span>{tournament.network}</span>
              </div>
              <div className="bigItemTournaments">
                <span>{tournament.name}</span>
              </div>
              <div className="mediumItemTournaments">
                <span>{prettyDate(tournament.date)}</span>
              </div>
              <div>
                <span>{tournament.type}</span>
              </div>
              <div>
                <span>{tournament.amount}</span>
              </div>
              <div>
                <span>{tournament.status}</span>
              </div>
              <div
                className="mediumItemTournaments"
                style={{ display: 'flex', gap: 5 }}
              >
                {tournament.status === 'Pendente' ? (
                  <>
                    <CustomButton
                      text="ENVIAR"
                      small
                      mode="green"
                      onClick={(e) => {
                        console.log('ok');
                        e.stopPropagation();
                      }}
                    />
                    <CustomButton
                      text="CANCELAR"
                      small
                      mode="red"
                      onClick={(e) => {
                        console.log('ok');
                        e.stopPropagation();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <CustomButton
                      text="EDITAR"
                      small
                      mode="gray"
                      onClick={(e) => {
                        console.log('ok');
                        e.stopPropagation();
                      }}
                    />
                    <CustomButton
                      text="DETALHES"
                      small
                      mode="gray"
                      onClick={(e) => {
                        console.log('ok');
                        e.stopPropagation();
                      }}
                    />
                  </>
                )}
              </div>
            </ExpandCardFlex>
          ))}
        </div>
        <PagingButtons changePage={changePage} page={page} max={pagesTotal} />
        <FilterModal
          email="string qualquer"
          onClose={() => setShowFilterList(false)}
          open={showFilterList}
          onSave={() => console.log('null')}
        />
        <ManualModal
          email="string qualquer"
          onClose={() => setShowAttachMoney(false)}
          open={showAttachMoney}
          onSave={() => console.log('null')}
        />
      </div>
    </>
  );
};

export default Index;
