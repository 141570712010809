import React, { useContext, useEffect } from 'react';
import './styles.css';
import swal from 'sweetalert';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import api from 'services/api';
import { Context } from 'context/authContext';

interface MatchParams {
  uidb64: string;
  token: string;
}

type Props = RouteComponentProps<MatchParams>;

const ChangePassword: React.FC<Props> = ({ match }: Props) => {
  const { checkAuth } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    const submit = (): void => {
      api
        .get(`profiles/verify/${match.params.uidb64}/${match.params.token}`)
        .then(() => {
          swal('Pronto!', 'Conta verificada com sucesso.', 'success');
          history.replace('/');
        })
        .catch((error) => {
          const msg = error.response?.data.detail || error.message;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });

          history.replace('/');
        });
    };
    submit();
  }, [checkAuth, history, match.params.token, match.params.uidb64]);

  return (
    <div className="verify">
      <h2>Verificando conta...</h2>
      <div className="loader" style={{ alignSelf: 'center' }} />
    </div>
  );
};

export default ChangePassword;
