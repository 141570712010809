import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import './styles.css';

const useStyles = makeStyles({
  text: {
    padding: 6,
  },
});

interface Props extends ButtonProps {
  text: string;
  children?: JSX.Element | null;
  mode?:
    | 'primary'
    | 'outlined'
    | 'gray'
    | 'green'
    | 'red'
    | 'spotlight'
    | 'red-outlined'
    | 'activated'
    | 'deactivated';
  small?: boolean;
}

const Index: React.FC<Props> = ({
  text,
  children,
  mode,
  small,
  onClick,
  ...props
}: Props) => {
  const classes = useStyles();

  const customButton = (): string => {
    let result = 'customButton';
    if (mode === 'outlined') result = 'customButton withoutBackground';
    else if (mode === 'red-outlined') result = 'customButtom redOutlined';
    else if (mode === 'gray') result = 'customButtom grayBackground';
    else if (mode === 'green') result = 'customButtom greenBackground';
    else if (mode === 'red') result = 'customButtom redBackground';
    else if (mode === 'spotlight') result = 'customButton spotlight';
    else if (mode === 'activated') result = 'customButton activated';
    else if (mode === 'deactivated') result = 'customButton deactivated';
    return result;
  };

  return (
    <Button
      onClick={onClick}
      classes={{ text: classes.text }}
      className={customButton()}
      style={{ height: small ? 25 : 35, fontSize: small ? 12 : 16 }}
      {...props}
    >
      {text} {children}
    </Button>
  );
};

Index.defaultProps = {
  mode: 'primary',
  children: null,
  small: false,
};

export default Index;
