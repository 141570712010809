import React, { useState, useEffect, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import CardFlex from 'components/CardFlex';
import DeleteButton from 'components/DeleteButton';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import SubContentViewProfile from 'components/SubContentViewProfile';
import Modal from 'components/Modal';
import InvalidityMsg from 'components/InvalidityMsg';
import api from 'services/api';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import { Context } from 'context/authContext';
import ModalDelete from '../ModalDelete';

export interface IWallet {
  id: number;
  name: string;
  holderName: string;
  number: string;
  createdAt: Date;
}

const Wallets: React.FC = () => {
  const userId = Cookies.get('sigma_userid');
  const { checkAuth } = useContext(Context);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editWallet, setEditWallet] = useState<boolean>(false);
  const [openModalWallet, setOpenModalWallet] = useState<boolean>(false);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  const [walletToDelete, setWalletToDelete] = useState({
    id: '',
    holderName: '',
  });
  const [newWallet, setNewWallet] = useState({
    name: { value: '', invalidity: '' },
    holderName: { value: '', invalidity: '' },
    number: { value: '', invalidity: '' },
  });

  const mapBackendWallets = (
    backendWallet: Record<string, string>
  ): IWallet => ({
    id: parseInt(backendWallet.id, 10),
    name: backendWallet.name,
    holderName: backendWallet.holder_name,
    number: backendWallet.number,
    createdAt: new Date(backendWallet.created_at),
  });

  useEffect(() => {
    api
      .get(`profiles/${userId}/wallets`)
      .then((resp) => {
        setWallets(resp.data.map(mapBackendWallets));
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
  }, [reload, userId, checkAuth]);

  const cleanNewWallet = (): void => {
    setNewWallet({
      name: { value: '', invalidity: '' },
      holderName: { value: '', invalidity: '' },
      number: { value: '', invalidity: '' },
    });
  };

  const toggleEditWallet = (): void => {
    setEditWallet(!editWallet);
  };
  const toggleModalWallet = (): void => {
    if (openModalWallet) {
      cleanNewWallet();
    }
    setOpenModalWallet(!openModalWallet);
  };

  const onChangeNameWallet = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const { value } = event.target;
    const oldName = newWallet.name;
    const newName = { ...oldName, value: value as string };

    setNewWallet({ ...newWallet, name: newName });
  };

  const onChangeHolderNameWallet = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    const oldHolderName = newWallet.holderName;
    const newHolderName = { ...oldHolderName, value };

    setNewWallet({ ...newWallet, holderName: newHolderName });
  };

  const onChangeNumberWallet = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    const oldNumber = newWallet.number;
    const newNumber = { ...oldNumber, value };

    setNewWallet({ ...newWallet, number: newNumber });
  };

  const addWallet = (): void => {
    const { name, number, holderName } = newWallet;

    name.invalidity = name.value ? '' : 'Selecione uma carteira';
    holderName.invalidity = holderName.value ? '' : 'Digite o nome';
    number.invalidity = number.value ? '' : 'Digite o número';

    setNewWallet({ name, holderName, number });

    if (!name.invalidity && !holderName.invalidity && !number.invalidity)
      api
        .post(`profiles/${userId}/wallets`, {
          name: name.value.toLowerCase(),
          holder_name: holderName.value,
          number: number.value,
        })
        .then(() => {
          setReload((_reload) => !_reload);
          swal('Adicionado com sucesso!', '', 'success');
        })
        .catch((error) => {
          const msg = error.response?.data.detail || error.message;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
    toggleModalWallet();
  };

  const deleteWallet = (id: string): void => {
    api
      .delete(`profiles/${userId}/wallets/${id}`)
      .then(() => {
        swal('A carteira foi excluída', '', 'success');
        setReload((_reload) => !_reload);
        setWalletToDelete({ id: '', holderName: '' });
      })
      .catch((error) => {
        setWalletToDelete({ id: '', holderName: '' });
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  };

  function getWalletName(name: string): string {
    switch (name) {
      case 'ecopayz':
        return 'EcoPayz';
      case 'luxon':
        return 'Luxon';
      default:
        return '';
    }
  }

  return (
    <>
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 300"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100" height="40" />
            <rect x="0" y="50" rx="10" ry="10" width="90%" height="25" />
            <rect x="0" y="120" rx="10" ry="10" width="13%" height="30" />
            <rect x="250" y="120" rx="10" ry="10" width="15%" height="30" />
            <rect x="525" y="120" rx="10" ry="10" width="17%" height="30" />
            <rect x="800" y="120" rx="10" ry="10" width="17%" height="30" />
            <rect x="0" y="170" rx="10" ry="10" width="100%" height="40" />
            <rect x="0" y="250" rx="10" ry="10" width="90" height="35" />
          </ContentLoader>
        </div>
      ) : (
        <>
          <SubContentViewProfile
            editMode={editWallet}
            onClick={() => toggleEditWallet()}
            mainFunction={() => toggleModalWallet()}
            type="wallet"
            title="CARTEIRAS"
            description="Certifique-se de que todos os dados cadastrados estão atualizados e corretos."
          >
            <CardFlex title>
              <div>
                <span>Carteira</span>
              </div>
              <div style={{ flex: 2 }}>
                <span>Nome</span>
              </div>
              <div>
                <span>Número</span>
              </div>
              <div style={{ flex: 2 }}>
                <span>Adicionado em</span>
              </div>
            </CardFlex>
            {wallets.map((item: IWallet) => {
              return (
                <CardFlex key={item.number}>
                  <div>
                    <span>{getWalletName(item.name)}</span>
                  </div>
                  <div style={{ flex: 2 }}>
                    <span>{item.holderName}</span>
                  </div>
                  <div>
                    <span>{item.number}</span>
                  </div>
                  <div style={{ flex: 2 }}>
                    <span>{item.createdAt.toLocaleString()}</span>
                  </div>
                  {editWallet && (
                    <div style={{ position: 'absolute', top: -8, right: -8 }}>
                      <DeleteButton
                        small
                        onClick={() => {
                          setWalletToDelete({
                            id: String(item.id),
                            holderName: item.holderName,
                          });
                        }}
                      />
                    </div>
                  )}
                </CardFlex>
              );
            })}
          </SubContentViewProfile>
          <Modal open={openModalWallet} setOpen={() => toggleModalWallet()}>
            <div className="modal">
              <div style={{ marginBottom: 20, fontWeight: 'bold' }}>
                <span>ADICIONAR CARTEIRA</span>
              </div>
              <CustomSelect
                label="Carteira"
                id="carteira"
                name="carteira"
                error={newWallet.name.invalidity}
                onChange={onChangeNameWallet}
                value={newWallet.name.value}
              >
                <MenuItem value="EcoPayz">EcoPayz</MenuItem>
                <MenuItem value="Luxon">Luxon</MenuItem>
              </CustomSelect>
              <InvalidityMsg msg={newWallet.name.invalidity} />
              <CustomInput
                label="Nome"
                id="nome"
                name="nome"
                error={newWallet.holderName.invalidity}
                onChange={onChangeHolderNameWallet}
                value={newWallet.holderName.value}
              />
              <InvalidityMsg msg={newWallet.holderName.invalidity} />

              <CustomInput
                label="Número"
                id="numero"
                name="numero"
                error={newWallet.number.invalidity}
                onChange={onChangeNumberWallet}
                value={newWallet.number.value}
              />
              <InvalidityMsg msg={newWallet.number.invalidity} />

              <div style={{ marginTop: 20 }}>
                <CustomButton
                  onClick={() => {
                    addWallet();
                  }}
                  text="ADICIONAR"
                />
              </div>
            </div>
          </Modal>
          <ModalDelete
            open={walletToDelete.id !== ''}
            title="EXCLUIR CARTEIRA"
            onConfirm={() => deleteWallet(walletToDelete.id)}
            message={
              <p>
                Excluir a carteira <u>{walletToDelete.holderName}</u>?
              </p>
            }
            onClose={() => setWalletToDelete({ id: '', holderName: '' })}
          />
        </>
      )}
    </>
  );
};

export default Wallets;
