import React from 'react';
import CustomSelect from 'components/CustomSelect';
import CustomButton from 'components/CustomButton';
import { MenuItem } from '@material-ui/core';
import Modal from 'components/Modal';

import './styles.css';

interface Props {
  role: string;
  open: boolean;
  playerName: string;
  setRole: (role: string) => void;
  saveRole: () => void;
  onClose: () => void;
  onSave: () => void;
}

const index: React.FC<Props> = ({
  role,
  open,
  playerName,
  setRole,
  saveRole,
  onClose,
  onSave,
}: Props) => {
  return (
    <Modal open={open} setOpen={onClose}>
      <div>
        <header className="headerPlayerName">{playerName}</header>
        <div>
          <CustomSelect
            label="FUNÇÃO"
            onChange={(e) => setRole(e.target.value as string)}
            value={role}
          >
            <MenuItem value="player">JOGADOR</MenuItem>
            <MenuItem value="admin">ADMINISTRADOR</MenuItem>
          </CustomSelect>
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 15,
          }}
        >
          <CustomButton
            onClick={() => {
              onSave();
              saveRole();
            }}
            text="SALVAR"
            mode="green"
          />
          <CustomButton onClick={onClose} text="CANCELAR" mode="red" />
        </div>
      </div>
    </Modal>
  );
};

export default index;
