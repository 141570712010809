/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import CustomButton from 'components/CustomButton';
import { IWallet } from 'components/ViewDashboard/ViewProfile/Wallets';
import { INetwork } from 'components/ViewDashboard/ViewProfile/Networks';
import GroupAddRounded from '@mui/icons-material/GroupAddRounded';
import AddPlayerModal from 'components/ViewDashboard/ViewPlayers/AddPlayerModal';
import InfoPlayerBox, { PlayerInfo } from 'components/InfoPlayerBox';
import { PlayerDetailedInfo } from 'components/InfoPlayerBox/DetailedHeaderInfo';
import { validateEmail } from 'util/validation';
import PagingButtons from 'components/PagingButtons';
import SearchField from 'components/SearchField';
import swal from 'sweetalert';
import './styles.css';
import api from 'services/api';
import { Context } from 'context/authContext';

export interface IProfile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  networks: INetwork[];
  wallets: IWallet[];
}

export interface ITeam {
  id: number;
  name: string;
}

export interface IPlayer {
  id: number;
  profile: IProfile;
  team: ITeam;
  role: string;
  deal: number;
  beginning_date: string;
}

const Index: React.FC = () => {
  // const [players, setPlayers] = useState<Array<PlayerInfo>>([]);
  const { checkAuth } = useContext(Context);
  // const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailToAdd, setEmailToAdd] = useState({ value: '', invalidity: '' });
  const [showEmailModal, setShowEmailModal] = useState(false);

  const TeamId = 1;

  const [updated, setUpdated] = useState(false);

  const [players, setPlayers] = useState<IPlayer[]>([]);
  const [filterPlayers, setFilterPlayers] = useState<IPlayer[]>([]);
  const [filterTimer, setFilterTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`teams/${TeamId}/members`)
      .then((resp) => {
        const { members, id } = resp.data;
        const newPlayers = members.map((player: IPlayer) => {
          let newPlayer = player;
          if (player.role === 'player') {
            newPlayer = { ...player, role: 'Jogador' };
          }
          if (player.role === 'treasurer') {
            newPlayer = { ...player, role: 'Tesoureiro' };
          }
          if (player.role === 'admin') {
            newPlayer = { ...player, role: 'Administrador' };
          }
          if (player.role === 'player_treasurer') {
            newPlayer = { ...player, role: 'Jogador/Tesoureiro' };
          }
          return newPlayer;
        });
        setPlayers(newPlayers);
        setFilterPlayers(newPlayers);
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
    setUpdated(false);
  }, [updated, checkAuth]);

  const onAddEmail = (): void => {
    const invalidityEmail = validateEmail(emailToAdd.value);
    setEmailToAdd({ ...emailToAdd, invalidity: invalidityEmail });

    if (!invalidityEmail) {
      api
        .post(`/teams/${TeamId}/members`, { email: emailToAdd.value })
        .then(() => {
          swal(
            'Adicionado com sucesso!',
            `O jogador ${emailToAdd.value} foi adicionado.`,
            'success'
          );
          setUpdated(true);
          setShowEmailModal(false);
          setEmailToAdd({ value: '', invalidity: '' });
        })
        .catch((error) => {
          const msg = error.response?.data.detail;
          swal('Algum erro aconteceu', msg, 'error').then(() => {
            checkAuth(error.response?.status);
          });
        });
    }
  };

  const applyFilter = (filterInput: string): void => {
    const filteredList = players.filter(
      (player) =>
        player.profile.first_name.toLowerCase().includes(filterInput) ||
        player.profile.last_name.toLowerCase().includes(filterInput)
    );

    if (filteredList.length > 0 && filterInput !== '') {
      setFilterPlayers(filteredList);
    } else if (filteredList.length === 0 && filterInput !== '') {
      swal('Nenhum resultado encontrado', '', 'info');
      setFilterPlayers([]);
    } else {
      setFilterPlayers(players);
    }
  };

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      setFilterTimer(undefined);
    }

    const { value } = event.target;
    const filterInput = value.toLowerCase();
    setFilterTimer(setTimeout(() => applyFilter(filterInput), 1500));
  };

  return (
    <div className="containerTournamentsList">
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 600"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100" height="30" />
            <rect x="0" y="50" rx="10" ry="10" width="100%" height="100" />
            <rect x="0" y="170" rx="10" ry="10" width="200" height="30" />
            <rect x="750" y="170" rx="10" ry="10" width="250" height="30" />
            <rect x="0" y="230" rx="10" ry="10" width="100%" height="100" />
            <rect x="0" y="350" rx="10" ry="10" width="100%" height="100" />
            <rect x="0" y="470" rx="10" ry="10" width="100%" height="100" />
          </ContentLoader>
        </div>
      ) : (
        <>
          <div className="headerView">
            <CustomButton text="JOGADORES" mode="primary" disabled />
          </div>

          <div className="overview" style={{ marginTop: 20 }}>
            <div>
              <span className="fontTitle">Quantidade</span>
              <span>52</span>
            </div>
            <div>
              <span className="fontTitle">Pendências</span>
              <span>0</span>
            </div>
            {/* <div>
          <span className="fontTitle">Caixas</span>
          <span>$55.400</span>
        </div>
        <div>
          <span className="fontTitle">MakeUp</span>
          <span>$-134.408</span>
        </div>
        <div>
          <span className="fontTitle">Banco</span>
          <span>$68.400</span>
        </div>
        <div>
          <span className="fontTitle">Lucro Total</span>
          <span>$1.868.166</span>
        </div>
        <div>
          <span className="fontTitle">Lucro Time</span>
          <span>$1.868.166</span>
        </div> */}
          </div>

          <div className="searchPlayerContainer">
            <CustomButton
              text="ADICIONAR JOGADOR"
              style={{ height: 'auto' }}
              mode="outlined"
              onClick={() => setShowEmailModal(true)}
            >
              <GroupAddRounded style={{ marginLeft: 5 }} />
            </CustomButton>
            <SearchField onChange={onChangeFilter} label="Nome do Jogador" />
          </div>

          <hr
            className="lineDiv"
            style={{ borderWidth: 2, marginBottom: 15 }}
          />

          <div className="playersList">
            {filterPlayers.map((player) => (
              <InfoPlayerBox
                key={player.id}
                players={players}
                setPlayers={setPlayers}
                id={player.id}
                profile={player.profile}
                team={player.team}
                role={player.role}
                deal={player.deal}
                beginning_date={player.beginning_date}
                teamId={TeamId}
              />
            ))}
          </div>
          {/*  */}
          {/* <PagingButtons changePage={() => console.log('ok')} max={5} page={page} /> */}
          <AddPlayerModal
            email={emailToAdd}
            setEmail={setEmailToAdd}
            onClose={() => setShowEmailModal(false)}
            open={showEmailModal}
            onSave={onAddEmail}
          />
        </>
      )}
    </div>
  );
};

export default Index;
