import React, { useContext, useEffect, useState } from 'react';
import CardDashboard from 'components/CardDashboard';
import api from 'services/api';
import swal from 'sweetalert';
import {
  CardViewType,
  horizontalCardStyles,
  cardStyles,
} from 'components/ViewCards';

import '../styles.css';
import { Context } from 'context/authContext';
import Cookies from 'js-cookie';

interface Props {
  view: CardViewType;
  setViewFunction: (view: CardViewType) => void;
  teamId: number | undefined;
}

const Index: React.FC<Props> = ({ view, setViewFunction, teamId }: Props) => {
  const { checkAuth } = useContext(Context);
  const [manageCard, setManageCard] = useState({
    coverage: 0,
    players: 0,
  });
  const [transactionsCard, setTransactionsCard] = useState({
    total: 0,
    roi: 0,
  });

  useEffect(() => {
    const token = Cookies.get('sigma_token');
    api
      .get(`summary/`, {
        headers: {
          Authorization: `Token ${token}`,
          TeamId: teamId,
        },
      })
      .then((resp) => {
        const { transactions, manage } = resp.data;
        setTransactionsCard({
          total: transactions.total,
          roi: transactions.roi,
        });
        setManageCard({
          coverage: manage.coverage,
          players: manage.players,
        });
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  }, [view, checkAuth, teamId]);

  return (
    <div className="cardsContainer">
      <div className="cardsView">
        <CardDashboard
          primKey="TORNEIOS"
          primValue={transactionsCard.total}
          secKey="ROI"
          secValue={transactionsCard.roi}
          secValueR="%"
          active={view === 'tournaments'}
          onClick={() => setViewFunction('tournaments')}
          style={cardStyles}
        />
        {/* <CardDashboard
          primKey="MAKEUP"
          secKey="CAIXA"
          primValue={`$${-134408}`}
          primValueR={`${3}%`}
          secValue={`$${112251}`}
          secValueR={`${162}%`}
          active={view === 'makeup'}
          onClick={() => setViewFunction('makeup')}
          style={cardStyles}
        /> */}
        <CardDashboard
          primKey="JOGADORES"
          secKey="COBERTURA"
          primValue={manageCard.players}
          secValue={manageCard.coverage}
          active={view === 'players'}
          onClick={() => setViewFunction('players')}
          style={cardStyles}
        />
      </div>
      <div className="cardsView">
        {/* <CardDashboard
          primKey="LIMITES"
          secKey="ÚLTIMA ALTERAÇÃO"
          primValue={`${9}`}
          secValueR="Por: ADMIN_NUMBER_9\nData: 29/11/2020"
          active={view === 'stakes'}
          onClick={() => setViewFunction('stakes')}
          style={{ ...cardStyles, ...horizontalCardStyles, top: 25 }}
        />
        <CardDashboard
          primKey="DATABASE"
          secKey="DATAS"
          primValue={`${8224791}`}
          secValueR="De: 04/05/2019\nAté: 29/11/2020"
          active={view === 'database'}
          onClick={() => setViewFunction('database')}
          style={{ ...cardStyles, ...horizontalCardStyles, top: 212 }}
        />
        <CardDashboard
          primKey="PENDÊNCIAS"
          secKey="DATA LIMITE"
          primValue={`${11}`}
          secValue="02/12/2020"
          active={view === 'audits'}
          onClick={() => setViewFunction('audits')}
          style={{ ...cardStyles, ...horizontalCardStyles, top: 400 }}
        /> */}
      </div>
    </div>
  );
};

export default Index;
