/* eslint-disable camelcase */
import React, { SetStateAction, useState } from 'react';
import Modal from 'components/Modal';
import InvalidityMsg from 'components/InvalidityMsg';
import CustomButton from 'components/CustomButton';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import MenuItem from '@material-ui/core/MenuItem';
import { validateCoupleFields } from 'util/validation';
import NameInput from './NameInput';
import './styles.css';

export interface IMembers {
  profile: number;
  network: string;
  nickname: string;
}

export interface IFilters {
  members: IMembers[];
  currencies: string[];
  games: string[];
  speeds: string[];
  structures: string[];
  formats: string[];
  prizes_structure: string[];
}

interface Dictionary<T> {
  [Key: string]: T;
}

interface Props {
  filters: IFilters;
  handModal: boolean;
  onFilter: React.Dispatch<SetStateAction<string | undefined>>;
  toggleHandModal: () => void;
}

interface ICoupleFields {
  values: string[];
  invalidity: string;
}

const Index: React.FC<Props> = ({
  filters,
  handModal,
  onFilter,
  toggleHandModal,
}: Props) => {
  const [name, setName] = useState('');
  const [dateError, setDateError] = useState('');
  const [beginDate, setBeginDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [networks, setNetworks] = useState<string[]>([]);
  const [nicknames, setNicknames] = useState<string[]>([]);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [games, setGames] = useState<string[]>([]);
  const [structures, setStructures] = useState<string[]>([]);
  const [formats, setFormats] = useState<string[]>([]);
  const [prize, setPrize] = useState<string[]>([]);
  const [speeds, setSpeeds] = useState<string[]>([]);
  const [stakePlusRake, setStakePlusRake] = useState<ICoupleFields>({
    values: ['', ''],
    invalidity: '',
  });
  const [guarantee, setGuarantee] = useState<ICoupleFields>({
    values: ['', ''],
    invalidity: '',
  });
  const [entries, setEntries] = useState<ICoupleFields>({
    values: ['', ''],
    invalidity: '',
  });

  const changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setName(value);
  };

  const changeBeginDate = (date: Date | null): void => {
    setBeginDate(date);
    setDateError('');
  };

  const changeEndDate = (date: Date | null): void => {
    setEndDate(date);
    setDateError('');
  };

  const changeNetworks = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setNetworks(event.target.value as string[]);
  };

  const changeNicknames = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setNicknames(event.target.value as string[]);
  };

  const changeCurrencies = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setCurrencies(event.target.value as string[]);
  };

  const changeGames = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setGames(event.target.value as string[]);
  };

  const changeStructures = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setStructures(event.target.value as string[]);
  };

  const changeFormats = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setFormats(event.target.value as string[]);
  };

  const changePrize = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setPrize(event.target.value as string[]);
  };

  const changeSpeeds = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSpeeds(event.target.value as string[]);
  };

  const changeMinStakePlusRake = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setStakePlusRake({
      values: [String(value), stakePlusRake.values[1]],
      invalidity: '',
    });
  };

  const changeMaxStakePlusRake = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setStakePlusRake({
      values: [stakePlusRake.values[0], String(value)],
      invalidity: '',
    });
  };

  const changeMinGuarantee = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setGuarantee({
      values: [String(value), guarantee.values[1]],
      invalidity: '',
    });
  };

  const changeMaxGuarantee = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setGuarantee({
      values: [guarantee.values[0], String(value)],
      invalidity: '',
    });
  };

  const changeMinEntries = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setEntries({ values: [String(value), entries.values[1]], invalidity: '' });
  };

  const changeMaxEntries = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setEntries({ values: [entries.values[0], String(value)], invalidity: '' });
  };

  const buildNetworkQueryJson = (filterType: string): string => {
    const networkQuery: Dictionary<(string | number)[][]> = {};

    let filteredNetwork = networks;
    if (filterType === 'nickname') {
      filteredNetwork = filters.members.map((item) => item.network);
    }

    filteredNetwork.forEach((networkName) => {
      const networkMembers = filters.members
        .filter((member) => {
          switch (filterType) {
            case 'both':
              return nicknames.includes(member.nickname);
            case 'network':
              return member.network === networkName;
            case 'nickname':
              return (
                member.network === networkName &&
                nicknames.includes(member.nickname)
              );
            default:
              return false;
          }
        })
        .map((networkMember) => [
          networkMember.nickname,
          networkMember.profile,
        ]);

      networkQuery[networkName] = networkMembers;
    });

    return JSON.stringify(networkQuery);
  };

  const applyFilter = (): void => {
    let queryParams = '';
    if (name) {
      queryParams += `&Contains=${name}`;
    }
    if (beginDate && endDate) {
      queryParams += `&Dates=${beginDate.toISOString()}~${endDate.toISOString()}`;
    }
    if (currencies.length > 0) {
      queryParams += `&Currencies=${currencies.join(',')}`;
    }
    if (games.length > 0) {
      queryParams += `&Games=${games.join(',')}`;
    }
    if (structures.length > 0) {
      queryParams += `&Structures=${structures.join(',')}`;
    }
    if (formats.length > 0) {
      queryParams += `&Formats=${formats.join(',')}`;
    }
    if (prize.length > 0) {
      queryParams += `&Prizes=${prize.join(',')}`;
    }
    if (speeds.length > 0) {
      queryParams += `&Speeds=${speeds.join(',')}`;
    }

    // ====> Network && Nickname <====
    const filledNickname = nicknames.length > 0;
    const filledNetwork = networks.length > 0;
    if (filledNickname && filledNetwork) {
      queryParams += `&Networks=${buildNetworkQueryJson('both')}`;
    } else if (filledNickname) {
      queryParams += `&Networks=${buildNetworkQueryJson('nickname')}`;
    } else if (filledNetwork) {
      queryParams += `&Networks=${buildNetworkQueryJson('network')}`;
    }

    // ====> StakePlusRake <====
    const stakePlusRake1 = stakePlusRake.values[0];
    const stakePlusRake2 = stakePlusRake.values[1];
    if (stakePlusRake1 && stakePlusRake2) {
      queryParams += `&Stakes=${`${stakePlusRake1}~${stakePlusRake2}`}&`;
    }

    // ====> Guarantee <====
    const guarantee1 = guarantee.values[0];
    const guarantee2 = guarantee.values[1];
    if (guarantee1 && guarantee2) {
      queryParams += `&Guarantees=${`${guarantee1}~${guarantee2}`}&`;
    }

    // ====> Entries <====
    const entries1 = entries.values[0];
    const entries2 = entries.values[1];
    if (entries1 && entries2) {
      queryParams += `&Entries=${`${entries1}~${entries2}`}&`;
    }

    onFilter(queryParams);
    toggleHandModal();
  };

  const handleSubmit = (): void => {
    const invalidityDates = validateCoupleFields(beginDate, endDate);

    const invalidityEntries = validateCoupleFields(
      entries.values[0],
      entries.values[1]
    );

    const invalidityStake = validateCoupleFields(
      stakePlusRake.values[0],
      stakePlusRake.values[1]
    );

    const invalidityGuarantee = validateCoupleFields(
      guarantee.values[0],
      guarantee.values[1]
    );

    if (
      invalidityDates ||
      invalidityEntries ||
      invalidityStake ||
      invalidityGuarantee
    ) {
      setDateError(invalidityDates);
      setEntries({ ...entries, invalidity: invalidityEntries });
      setStakePlusRake({ ...stakePlusRake, invalidity: invalidityStake });
      setGuarantee({ ...guarantee, invalidity: invalidityGuarantee });
    } else {
      applyFilter();
    }
  };

  return (
    <Modal open={handModal} setOpen={toggleHandModal}>
      <div className="filterModal">
        <b>FILTRAR</b>
        <hr className="lineDiv" />
        <div className="bodyFilterModal">
          <NameInput value={name} onChange={changeName} />
          <div className="rowWithFilterModalMsg">
            <div className="rowFilterModal">
              <div>
                <CustomDatePicker
                  label="DATA INICIO"
                  value={beginDate}
                  onChange={changeBeginDate}
                  error={dateError !== ''}
                />
              </div>
              <div>
                <CustomDatePicker
                  label="DATA FIM"
                  value={endDate}
                  onChange={changeEndDate}
                  error={dateError !== ''}
                />
              </div>
            </div>
            <InvalidityMsg msg={dateError} />
          </div>
          <div className="rowFilterModal">
            <div>
              <CustomSelect
                multiple
                label="SALAS"
                onChange={changeNetworks}
                value={networks}
              >
                {filters.members
                  .map((member) => member.network)
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="NICKNAMES"
                onChange={changeNicknames}
                value={nicknames}
              >
                {filters.members
                  .map((member) => member.nickname)
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="MOEDAS"
                onChange={changeCurrencies}
                value={currencies}
              >
                {filters.currencies.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="JOGOS"
                onChange={changeGames}
                value={games}
              >
                {filters.games.map((item) => (
                  <MenuItem
                    key={Object.values(item)[0]}
                    value={Object.values(item)[0]}
                  >
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
          </div>
          <div className="rowFilterModal">
            <div>
              <CustomSelect
                multiple
                label="ESTRUTURA"
                onChange={changeStructures}
                value={structures}
              >
                {filters.structures.map((item) => (
                  <MenuItem
                    key={Object.values(item)[0]}
                    value={Object.values(item)[0]}
                  >
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="FORMATO"
                onChange={changeFormats}
                value={formats}
              >
                {filters.formats.map((item) => (
                  <MenuItem
                    key={Object.values(item)[0]}
                    value={Object.values(item)[0]}
                  >
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="PREMIAÇÃO"
                onChange={changePrize}
                value={prize}
              >
                {filters.prizes_structure.map((item) => (
                  <MenuItem
                    key={Object.values(item)[0]}
                    value={Object.values(item)[0]}
                  >
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
            <div>
              <CustomSelect
                multiple
                label="VELOCIDADE"
                onChange={changeSpeeds}
                value={speeds}
              >
                {filters.speeds.map((item) => (
                  <MenuItem
                    key={Object.values(item)[0]}
                    value={Object.values(item)[0]}
                  >
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
          </div>
          <div className="rowFilterModal">
            <div className="rowWithFilterModalMsg">
              <span className="modalTitle">LIMITES</span>
              <div>
                <CustomInput
                  label="Min"
                  placeholder="0"
                  type="number"
                  min="0"
                  max={stakePlusRake.values[1] || `215`}
                  value={stakePlusRake.values[0]}
                  error={stakePlusRake.invalidity}
                  onChange={changeMinStakePlusRake}
                />
                <CustomInput
                  label="Max"
                  placeholder="215"
                  type="number"
                  min={stakePlusRake.values[0] || `0`}
                  max="215"
                  value={stakePlusRake.values[1]}
                  error={stakePlusRake.invalidity}
                  onChange={changeMaxStakePlusRake}
                />
              </div>
              <InvalidityMsg msg={stakePlusRake.invalidity} />
            </div>
            <div className="rowWithFilterModalMsg">
              <span className="modalTitle">GARANTIDO</span>
              <div>
                <CustomInput
                  label="Min"
                  placeholder="0"
                  type="number"
                  min="0"
                  max={guarantee.values[1] || `500`}
                  value={guarantee.values[0]}
                  error={guarantee.invalidity}
                  onChange={changeMinGuarantee}
                />
                <CustomInput
                  label="Max"
                  placeholder="500"
                  type="number"
                  min={guarantee.values[0] || `0`}
                  max="500"
                  value={guarantee.values[1]}
                  error={guarantee.invalidity}
                  onChange={changeMaxGuarantee}
                />
              </div>
              <InvalidityMsg msg={guarantee.invalidity} />
            </div>
            <div className="rowWithFilterModalMsg">
              <span className="modalTitle">ENTRADAS</span>
              <div>
                <CustomInput
                  min="1"
                  label="Min"
                  type="number"
                  placeholder="1"
                  max={entries.values[1] || `3`}
                  value={entries.values[0]}
                  error={entries.invalidity}
                  onChange={changeMinEntries}
                />
                <CustomInput
                  max="3"
                  label="Max"
                  type="number"
                  placeholder="3"
                  min={entries.values[0] || `1`}
                  value={entries.values[1]}
                  error={entries.invalidity}
                  onChange={changeMaxEntries}
                />
              </div>
              <InvalidityMsg msg={entries.invalidity} />
            </div>
          </div>
        </div>
        <hr className="lineDiv" />
        <CustomButton text="APLICAR" onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default Index;
