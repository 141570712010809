import React from 'react';
import ProfitChart from './Profit';
import './styles.css';

interface Props {
  teamId: number | undefined;
}

const index: React.FC<Props> = ({ teamId }) => {
  const currentChart = 'profit';
  return (
    <div className="mainChartContainer">
      <ProfitChart teamId={teamId} currentChart={currentChart} />
    </div>
  );
};

export default index;
