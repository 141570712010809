import React, { InputHTMLAttributes } from 'react';
import TextField from '@material-ui/core/TextField';
import './styles.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  placeholder?: string;
}

const Index: React.FC<Props> = ({ label, error, ...props }: Props) => {
  return (
    <div className="customInput">
      <TextField
        inputProps={{ ...props }}
        label={label}
        fullWidth
        variant="outlined"
        margin="dense"
        disabled={props.disabled}
        error={error !== ''}
      />
    </div>
  );
};

Index.defaultProps = {
  error: '',
  placeholder: undefined,
};

export default Index;
