/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomButton from 'components/CustomButton';
import ExpandCardFlex from 'components/ExpandCardFlex';
import CardFlex from 'components/CardFlex';
import PagingButtons from 'components/PagingButtons';
import api from 'services/api';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import { prettyDate, prettyFullDate } from 'util/conversor';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Context } from 'context/authContext';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Cookies from 'js-cookie';
import { AxiosRequestConfig } from 'axios';
import Filter from './Filter';

import './styles.css';

export interface IHeader {
  avg: number;
  prize: number;
  profit: number;
  roi: number;
  staked: number;
  total: number;
}

export interface ITournament {
  check: boolean;
  currency: string;
  date: string;
  entries: number;
  game: string;
  guarantee: number;
  name: string;
  network: string;
  player: string;
  position: number;
  prize: number;
  rake: number;
  sharkscope: string;
  speed: string;
  stake: number;
  structure: string;
  tid: number;
  total_entries: number;
  handhistory?: IHandhistory;
}

export interface IHandhistory {
  id: number;
  hands: number;
  timeline: ITime[];
}

export interface ITime {
  action: string;
  blinds: number;
  datetime: string;
  elapsed_time: string;
  hand_id: number;
  table_id: number;
}

export interface IMembers {
  profile: number;
  network: string;
  nickname: string;
}

export interface IFilters {
  members: IMembers[];
  currencies: string[];
  games: string[];
  speeds: string[];
  structures: string[];
  formats: string[];
  prizes_structure: string[];
}

interface Props {
  teamId: number | undefined;
}

const Index: React.FC<Props> = ({ teamId }: Props) => {
  const { checkAuth } = useContext(Context);
  const [styles, setStyles] = useState(true);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState<string>();
  const [pagesTotal, setPagesTotal] = useState(1);
  const [headerCard, setHeaderCard] = useState<IHeader>({
    avg: 0,
    prize: 0,
    profit: 0,
    roi: 0,
    staked: 0,
    total: 0,
  });
  const [tournaments, setTournaments] = useState<ITournament[]>([]);
  const [filters, setFilters] = useState<IFilters>({
    members: [],
    currencies: [],
    games: [],
    speeds: [],
    structures: [],
    formats: [],
    prizes_structure: [],
  });
  const [check, setCheck] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeDownloadButton, setActiveDownloadButton] =
    useState<boolean>(false);
  const [apiReqConfig, setApiReqConfig] = useState<
    AxiosRequestConfig | undefined
  >();

  useEffect(() => {
    if (tournaments.filter((t) => t.check).length > 0) {
      setActiveDownloadButton(true);
    } else {
      setActiveDownloadButton(false);
    }
  }, [tournaments]);

  useEffect(() => {
    let config;
    if (teamId) {
      config = {
        headers: {
          Authorization: `Token ${Cookies.get('sigma_token')}`,
          TeamId: teamId,
        },
      };
    }

    setPage(1);
    setApiReqConfig(config);
    setParams(undefined);
    setFilters({
      members: [],
      currencies: [],
      games: [],
      speeds: [],
      structures: [],
      formats: [],
      prizes_structure: [],
    });
  }, [teamId]);

  useEffect(() => {
    setIsLoading(true);
    const queryParams = params ? `?Page=${page}${params}` : `?Page=${page}`;
    api
      .get(`transactions/${queryParams}`, apiReqConfig)
      .then((resp) => {
        const { headers } = resp;
        const pages = parseInt(headers['pages-total'], 10);

        const { header, body, filters_available } = resp.data;
        const newTournaments = body.map((tournament: ITournament) => {
          const newTournament = tournament;
          newTournament.check = false;

          return newTournament;
        });
        setPagesTotal(pages);
        setHeaderCard(header);
        if (page > pages) {
          setPage(pages);
        } else {
          setTournaments(newTournaments);
          setFilters(filters_available);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
        setIsLoading(false);
      });
  }, [page, params, apiReqConfig, checkAuth]);

  const changeCheckAll = (newCheck: boolean): void => {
    let list = tournaments;
    list = list.map((tournament: ITournament) => {
      const newTournament = tournament;
      newTournament.check = newCheck;
      return newTournament;
    });

    setCheck(newCheck);
    setTournaments(list);
  };

  const changeCheck = (newCheck: boolean, id: number): void => {
    let list = tournaments;
    list = list.map((tournament) => {
      const newTournament = tournament;
      if (newTournament.tid === id) {
        newTournament.check = newCheck;
      }
      return newTournament;
    });

    setTournaments(list);
  };

  const changePage = (newPage: number): void => {
    if (newPage <= pagesTotal && newPage >= 1) {
      setPage(newPage);
    }
  };

  const toggleFilterModal = (): void => {
    setFilterModal(!filterModal);
  };

  return (
    <div className="containerTournaments">
      <Filter
        handModal={filterModal}
        toggleHandModal={toggleFilterModal}
        filters={filters}
        onFilter={setParams}
      />
      {isLoading ? (
        <div style={{ width: '100%', marginTop: 5 }}>
          <ContentLoader
            viewBox="0 0 1000 550"
            backgroundColor="#e5e5e5"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100" height="30" />
            <rect x="0" y="50" rx="8" ry="8" width="100%" height="100" />
            <rect x="0" y="170" rx="8" ry="8" width="10%" height="40" />
            <rect x="900" y="170" rx="8" ry="8" width="10%" height="40" />
            <rect x="0" y="230" rx="3" ry="3" width="100%" height="5" />
            <rect x="0" y="250" rx="8" ry="8" width="100%" height="25" />
            <rect x="0" y="300" rx="8" ry="8" width="100%" height="25" />
            <rect x="0" y="350" rx="8" ry="8" width="100%" height="25" />
            <rect x="0" y="400" rx="8" ry="8" width="100%" height="25" />
            <rect x="0" y="450" rx="8" ry="8" width="100%" height="25" />
            <rect x="0" y="500" rx="8" ry="8" width="100%" height="25" />
          </ContentLoader>
        </div>
      ) : (
        <>
          <div className="headerView">
            <CustomButton
              text="TORNEIOS"
              mode={styles ? 'primary' : 'outlined'}
              onClick={() => setStyles(true)}
            />
            {/* <CustomButton
              text="TRANSAÇÕES"
              mode={styles ? 'outlined' : 'primary'}
              onClick={() => setStyles(false)}
            /> */}
          </div>
          <div className="overview" style={{ marginTop: 20 }}>
            <div>
              <span className="fontTitle">Total</span>
              <span>
                <NumberFormat
                  value={headerCard.total}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">ROI</span>
              <span>
                <NumberFormat
                  value={headerCard.roi}
                  displayType="text"
                  suffix="%"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Média</span>
              <span>
                <NumberFormat
                  value={headerCard.avg}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  prefix="$"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Buy-in</span>
              <span>
                <NumberFormat
                  value={headerCard.staked}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  prefix="$"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Prize</span>
              <span>
                <NumberFormat
                  value={headerCard.prize}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  prefix="$"
                />
              </span>
            </div>
            <div>
              <span className="fontTitle">Lucro</span>
              <span>
                <NumberFormat
                  value={headerCard.profit}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  prefix="$"
                />
              </span>
            </div>
          </div>
          <div
            className="headerView"
            style={{ marginTop: 20, justifyContent: 'space-between' }}
          >
            <CustomButton
              small
              text="FILTRAR"
              mode="outlined"
              style={{ height: 'auto' }}
              onClick={toggleFilterModal}
            >
              <FilterAltRoundedIcon style={{ marginLeft: 5 }} />
            </CustomButton>
            <CustomButton
              small
              text="BAIXAR"
              mode={activeDownloadButton ? 'activated' : 'deactivated'}
              style={{ height: 'auto' }}
              onClick={() => console.log('ok')}
            >
              <FileDownloadRoundedIcon style={{ marginLeft: 5 }} />
            </CustomButton>
          </div>

          <hr className="lineDiv" style={{ marginTop: 20 }} />
          <CardFlex title>
            <div style={{ display: 'flex', flex: 'none' }}>
              <CustomCheckbox
                checked={check}
                onClick={() => changeCheckAll(!check)}
              />
            </div>
            <div className="fontTitle">
              <span>Sala</span>
            </div>
            <div className="fontTitle">
              <span>Data</span>
            </div>
            <div className="fontTitle">
              <span>Conta</span>
            </div>
            <div className="fontTitle bigItemTournaments">
              <span>Nome</span>
            </div>
            <div className="fontTitle">
              <span>Buy-in</span>
            </div>
            <div className="fontTitle">
              <span>Lucro</span>
            </div>
            <div className="fontTitle">
              <span>ID</span>
            </div>
          </CardFlex>
          <div className="tournaments">
            {tournaments.map((tournament, i) => (
              <ExpandCardFlex
                // Key as index to avoid duplicate values when use tid
                key={`${i + 1}`}
                header={
                  <>
                    <div style={{ flex: 'none' }}>
                      <CustomCheckbox
                        checked={tournament.check}
                        onClick={(e) => {
                          changeCheck(!tournament.check, tournament.tid);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <div>
                      <span>{tournament.name}</span>
                    </div>
                  </>
                }
                details={
                  <div className="detailsCardTournaments">
                    <div>
                      <div>
                        <span>Sala: {tournament.network}</span>
                        <span>Conta: {tournament.player}</span>
                        {tournament.handhistory && (
                          <span>
                            Número de mãos:{' '}
                            <NumberFormat
                              value={tournament.handhistory.hands}
                              thousandSeparator="."
                              decimalSeparator=","
                              displayType="text"
                            />
                          </span>
                        )}
                      </div>
                      <div>
                        <span>ID: {tournament.tid}</span>
                        <span>Início: {prettyFullDate(tournament.date)}</span>
                        <span>Jogo: {tournament.game}</span>
                        <span>Velocidade: {tournament.speed}</span>
                        <span>Estrutura: {tournament.structure}</span>
                      </div>
                      <div>
                        <span>
                          Buy-in: ${tournament.stake + tournament.rake} ($
                          {tournament.stake}+${tournament.rake})
                        </span>
                        <span>
                          Garantido:
                          <NumberFormat
                            value={tournament.guarantee}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            prefix=" $"
                            displayType="text"
                          />
                        </span>
                      </div>
                      <div>
                        <span>
                          Posição: {tournament.position}/
                          {tournament.total_entries}
                        </span>
                        <span>
                          Premiação:
                          <NumberFormat
                            value={tournament.prize}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            prefix=" $"
                            displayType="text"
                          />
                        </span>
                        <span>Entradas: {tournament.entries}</span>
                        <span>
                          Lucro:
                          <NumberFormat
                            value={
                              tournament.prize -
                              (tournament.stake + tournament.rake)
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            prefix=" $"
                            allowNegative
                            displayType="text"
                          />
                        </span>
                      </div>
                    </div>
                    {tournament.handhistory && (
                      <>
                        <div style={{ gap: 0 }}>
                          <div className="titleMediumBackground">
                            <span>LINHA DO TEMPO</span>
                          </div>
                          <hr className="lineGray" />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div className="cardHandHistoryTournaments">
                            <div>
                              <b>Ação</b>
                            </div>
                            <div>
                              <b>Blinds</b>
                            </div>
                            <div>
                              <b>Horário de Registro</b>
                            </div>
                            <div>
                              <b>Tempo Decorrido</b>
                            </div>
                            <div>
                              <b>ID Mão</b>
                            </div>
                            <div>
                              <b>ID Mesa</b>
                            </div>
                          </div>
                          {tournament.handhistory.timeline.map(
                            (time: ITime) => {
                              return (
                                <div className="cardHandHistoryTournaments">
                                  <div>
                                    <p>{time.action}</p>
                                  </div>
                                  <div>
                                    <p>
                                      <NumberFormat
                                        value={time.blinds}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        suffix="BB"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        displayType="text"
                                      />
                                    </p>
                                  </div>
                                  <div>
                                    <p>{prettyFullDate(time.datetime)}</p>
                                  </div>
                                  <div>
                                    <p>{time.elapsed_time}</p>
                                  </div>
                                  <div>
                                    <p>{time.hand_id}</p>
                                  </div>
                                  <div>
                                    <p>{time.table_id}</p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                    <div style={{ display: 'flex' }}>
                      <hr className="lineGray" />
                    </div>

                    <div style={{ justifyContent: 'flex-start' }}>
                      {tournament.handhistory && (
                        <CustomButton
                          text="HANDHISTORY"
                          small
                          mode="gray"
                          onClick={() => console.log('ok')}
                        />
                      )}
                      <CustomButton
                        text="SHARKSCOPE"
                        small
                        mode="gray"
                        onClick={() =>
                          window.open(tournament.sharkscope, '_blank')
                        }
                      />
                    </div>
                  </div>
                }
              >
                <div style={{ flex: 'none' }}>
                  <CustomCheckbox
                    checked={tournament.check}
                    onClick={(e) => {
                      changeCheck(!tournament.check, tournament.tid);
                      e.stopPropagation();
                    }}
                  />
                </div>
                <div>
                  <span>{tournament.network}</span>
                </div>
                <div>
                  <span>{prettyDate(tournament.date)}</span>
                </div>
                <div>
                  <span>{tournament.player}</span>
                </div>
                <div className="bigItemTournaments">
                  <span>{tournament.name}</span>
                </div>
                <div>
                  <span>
                    <NumberFormat
                      value={tournament.stake + tournament.rake}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      displayType="text"
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <NumberFormat
                      value={
                        tournament.prize - (tournament.stake + tournament.rake)
                      }
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      displayType="text"
                    />
                  </span>
                </div>
                <div>
                  <span>{tournament.tid}</span>
                </div>
              </ExpandCardFlex>
            ))}
          </div>
          <PagingButtons changePage={changePage} page={page} max={pagesTotal} />
        </>
      )}
    </div>
  );
};

export default Index;
