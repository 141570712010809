import React from 'react';
import './styles.css';

interface Props {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

const Index: React.FC<Props> = ({ style, children }: Props) => {
  return (
    <div className="greyRectangle" style={style}>
      {children}
    </div>
  );
};

Index.defaultProps = {
  style: {},
};

export default Index;
