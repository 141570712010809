/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes } from 'react';
import TextField from '@material-ui/core/TextField';
import './styles.css';

const Index: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  onChange,
  value,
}: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className="nameInput">
      <span id="textNameInput">CONTÉM</span>
      <TextField
        onChange={onChange}
        label="NOME DO TORNEIO"
        fullWidth
        variant="outlined"
        margin="dense"
        value={value}
      />
    </div>
  );
};

export default Index;
