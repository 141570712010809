import React, { useState } from 'react';
import './styles.css';

interface Props {
  children: JSX.Element | JSX.Element[];
  type?: 'flex' | 'around' | 'between';
  dense?: boolean;
  headerHeight?: number;
  header: JSX.Element | JSX.Element[];
  details: JSX.Element | JSX.Element[];
}

const Index: React.FC<Props> = ({
  children,
  type,
  dense,
  header,
  details,
  headerHeight,
}: Props) => {
  const [expand, setExpand] = useState(false);
  const [headerControl, setHeaderControl] = useState(false);
  const [amount, setAmount] = useState(true);

  const changeExpand = (): void => {
    if (amount) {
      setAmount(false);
      const value = !expand;
      setExpand(value);
      setTimeout(
        () => {
          setHeaderControl(value);
          setAmount(true);
        },
        headerControl ? 300 : 0
      );
    }
  };

  const customClass = (): string => {
    let res = 'defaultExpandCardFlex';
    if (type === 'around') res = 'aroundExpandCardFlex';
    else if (type === 'between') res = 'betweenExpandCardFlex';
    return res;
  };
  const customStyle = (): string => {
    let res = '0px';
    if (dense) res = '12px 40px';
    else res = '12px 20px';
    return res;
  };

  return (
    <div
      className={`expandCardFlex ${customClass()}`}
      style={{
        maxHeight: expand ? 900 : headerHeight,
        transition: 'max-height 0.3s linear',
      }}
    >
      {headerControl ? (
        <div
          className="headerExpandCardFlex  backgroundActive"
          style={{ padding: customStyle(), cursor: 'pointer' }}
          onClick={changeExpand}
          onKeyDown={changeExpand}
          role="button"
          tabIndex={0}
        >
          {header}
        </div>
      ) : (
        <div
          className="headerExpandCardFlex"
          style={{ padding: customStyle(), cursor: 'pointer' }}
          onClick={changeExpand}
          onKeyDown={changeExpand}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      )}
      <div style={{ padding: customStyle() }}>{details}</div>
    </div>
  );
};

Index.defaultProps = {
  type: 'flex',
  dense: false,
  headerHeight: 42,
};

export default Index;
