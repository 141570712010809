import React, { useState, useEffect, useContext } from 'react';
import ViewDashboard from 'components/ViewDashboard';
import ViewCards, { CardViewType, CardsType } from 'components/ViewCards';
import Charts from 'components/Charts';
import SelectTeam from 'components/SelectTeam';
import './styles.css';
import api from 'services/api';
import swal from 'sweetalert';
import { Context } from 'context/authContext';

// type rolesType = 'admin' | 'player' | 'treasurer' | 'player/treasurer';
export interface IAdminTeams {
  name: string;
  id: number | undefined;
  role: string;
}

const Index: React.FC = () => {
  const { checkAuth } = useContext(Context);
  const [view, setView] = useState<CardViewType>('tournaments');
  const [selectValue, setSelectValue] = useState<string>('Pessoal');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cardType, setCardType] = useState<CardsType>('player');
  const [teamId, setTeamId] = useState<number | undefined>(undefined);
  const [adminTeams, setAdminTeams] = useState<IAdminTeams[]>([
    { name: 'Pessoal', id: undefined, role: 'player' },
  ]);

  const showCharts = (viewName: string): boolean => {
    const result1 = cardType !== 'player';
    const result2 = viewName !== 'makeup' && viewName !== 'profile';

    return result1 || result2;
  };

  useEffect(() => {
    api
      .get('/summary')
      .then((resp) => {
        const { teams } = resp.data;
        const userRoles = teams.map((t: IAdminTeams) => t.role);
        const includesPlayer = userRoles.includes('player');

        if (includesPlayer) {
          setAdminTeams([...teams]);
        } else {
          const playerTeam = { name: 'Pessoal', id: undefined, role: 'player' };
          setAdminTeams([playerTeam, ...teams]);
        }
      })
      .catch((error) => {
        const msg = error.response?.data.detail || error.message;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  }, [checkAuth]);

  // // If the user is a player, add a player option on select component
  // useEffect(() => {
  //   if (
  //     roles.includes('player') &&
  //     !adminTeams.some((e) => e.name === 'Pessoal')
  //   ) {
  //     setAdminTeams((_adminTeams: IAdminTeams[]) => [
  //       { name: 'Pessoal' },
  //       ..._adminTeams,
  //     ]);
  //   } else if (!roles.includes('player')) {
  //     const idx = adminTeams.findIndex((item) => item.name === 'Pessoal');
  //     if (idx !== -1) {
  //       const adminTeamsCopy = adminTeams.slice();
  //       adminTeamsCopy.splice(idx, 1);
  //       setAdminTeams(adminTeamsCopy);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [roles]);

  const onSelectTeam = (
    name: string,
    role: string,
    id: number | undefined
  ): void => {
    setSelectValue(name);
    setView('tournaments');

    if (role !== 'player') {
      setTeamId(id);
      setCardType('team');
    } else {
      setTeamId(undefined);
      setCardType('player');
    }
  };

  return (
    <div className="containerDashboard">
      <div className="containerContent">
        <div className="playerNameContainer">
          {adminTeams.length > 1 ? (
            <SelectTeam
              value={selectValue}
              values={adminTeams}
              onSelectTeam={onSelectTeam}
            />
          ) : (
            <h2 className="titlePlayerName">{adminTeams[0].name}</h2>
          )}
        </div>
        <ViewCards
          view={view}
          type={cardType}
          teamId={teamId}
          setViewFunction={setView}
        />
        {showCharts(view) && <Charts teamId={teamId} />}
        <ViewDashboard
          type={view}
          dashboardType={selectValue}
          teamId={teamId}
        />
      </div>
    </div>
  );
};

export default Index;
