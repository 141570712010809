import React from 'react';
import PlayerCards from 'components/ViewCards/PlayerCards';
import TeamCards from 'components/ViewCards/TeamCards';
import './styles.css';

interface Props {
  view: CardViewType;
  setViewFunction: (view: CardViewType) => void;
  type: CardsType;
  teamId: number | undefined;
}
export type CardsType = 'player' | 'team';
export type CardViewType =
  | 'tournaments'
  | 'profile'
  | 'makeup'
  | 'manage'
  | 'players'
  | 'stakes'
  | 'database'
  | 'audits';

export const cardStyles: React.CSSProperties = {
  margin: '10px 20px 40px 20px',
};
export const horizontalCardStyles: React.CSSProperties = {
  position: 'absolute',
  right: 0,
};

const index: React.FC<Props> = ({
  view,
  setViewFunction,
  type,
  teamId,
}: Props) => {
  return (
    <div className="cardsContainer">
      {type === 'player' ? (
        <PlayerCards view={view} setViewFunction={setViewFunction} />
      ) : (
        <TeamCards
          view={view}
          setViewFunction={setViewFunction}
          teamId={teamId}
        />
      )}
    </div>
  );
};

export default index;
