import React, { useState } from 'react';
import './styles.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Image from 'material-ui-image';

interface Props {
  name: string;
  number?: string;
  notes: string;
  attachments: string[];
}

const Index: React.FC<Props> = ({
  name,
  number,
  notes,
  attachments,
}: Props) => {
  const [pic, setPic] = useState<string>(attachments[0]);
  const [isOpen, setIsOpen] = useState(false);

  const change = (image: string): void => {
    setPic(image);
    setIsOpen(true);
  };

  return (
    <div className="blockDetail">
      <div className="blockDetailFifty">
        <p>
          <strong>Nome: </strong>
          {name}
        </p>
        <p>
          <strong>Observações: </strong>
          {notes}
        </p>
        <p>
          <strong>Formato: </strong>
          Automático
        </p>
        {number && (
          <>
            <p>
              <strong>Número da conta: </strong>
              {number}
            </p>
            <p>
              <strong>Finalizada em: </strong>
              04/10/2020 22:23
            </p>
            <p>
              <strong>Responsável: </strong>
              Adriano Ribeiro
            </p>
          </>
        )}
      </div>
      <div className="blockDetailFifty">
        <strong style={{ marginBottom: 10 }}>Imagens</strong>
        <ul>
          {attachments.map((image) => (
            <li
              style={{
                display: 'table',
                width: 98,
                float: 'left',
                marginRight: 10,
                borderRadius: 5,
              }}
            >
              <Image
                style={{ cursor: 'zoom-in' }}
                src={image}
                cover
                onClick={() => change(image)}
                imageStyle={{ borderRadius: 5 }}
              />
            </li>
          ))}
        </ul>
        {isOpen && (
          <Lightbox
            mainSrc={pic}
            onCloseRequest={() => setIsOpen(false)}
            animationDuration={150}
          />
        )}
      </div>
    </div>
  );
};

Index.defaultProps = {
  number: undefined,
};

export default Index;
