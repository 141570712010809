import React, { InputHTMLAttributes } from 'react';
import './styles.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
}
const Index: React.FC<Props> = ({ onClick, checked }: Props) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      className="customCheckbox"
      onClick={onClick}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
    />
  );
};

export default Index;
