import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from 'theme/theme';
import { BrowserRouter } from 'react-router-dom';
import AppBar from 'components/AppBar';

import { AuthProvider } from './context/authContext';

import Routes from './Routes/index';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <div style={{ flexGrow: 1 }}>
            <AppBar />
          </div>
          <Routes />
        </MuiThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
