import * as React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { IAdminTeams } from 'pages/Dashboard';

import './styles.css';

interface Props {
  onSelectTeam: (name: string, role: string, id: number | undefined) => void;
  values: IAdminTeams[];
  value: string | null;
}
const SelectTeam: React.FC<Props> = ({
  onSelectTeam,
  value,
  values,
}: Props) => {
  return (
    <FormControl variant="outlined" className="FormControlSelectTeam">
      <Select className="selectTeamContainer" value={value}>
        {values.map((item) => (
          <MenuItem
            key={item.name}
            value={item.name}
            onClick={() => onSelectTeam(item.name, item.role, item?.id)}
          >
            <h3>{item.name}</h3>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTeam;
