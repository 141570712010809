import React, { EventHandler, useEffect } from 'react';
import SignButton from '../SignButton';
import './styles.css';

interface Props {
  title: string;
  textButton: string;
  handleSubmit: () => void;
  question: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}

const Index: React.FC<Props> = ({
  title,
  question,
  handleSubmit,
  textButton,
  children,
}: Props) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit]);
  return (
    <div className="signPage">
      <div className="signContainer">
        <div className="signHeader">
          <span>Sigma</span>
        </div>
        <div className="signContent">
          <div className="signName">
            <span>{title}</span>
          </div>
          {children}
          <SignButton text={textButton} onClick={handleSubmit} />
          <div className="signQuestion">{question}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
