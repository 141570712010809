import React, { useContext } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import ForgotPassword from 'pages/ForgotPassword';
import ChangePassword from 'pages/ChangePassword';
import Verify from 'pages/Verify';

import { Context } from 'context/authContext';

interface Props extends RouteProps {
  isPrivate?: boolean;
}

const CustomRoute = ({ isPrivate, ...rest }: Props): JSX.Element => {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <div />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />;
  }

  if (!isPrivate && authenticated) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...rest} />;
};

const routes: React.FC = () => (
  <Switch>
    <CustomRoute path="/" exact component={Login} />
    <CustomRoute path="/signup" exact component={Signup} />
    <CustomRoute path="/forgot-password" exact component={ForgotPassword} />
    <CustomRoute
      path="/change-password/:uidb64/:token"
      exact
      component={ChangePassword}
    />
    <CustomRoute path="/verify/:uidb64/:token" exact component={Verify} />
    <CustomRoute isPrivate path="/dashboard" component={Dashboard} />
    <Redirect from="*" to="/" />
  </Switch>
);

CustomRoute.defaultProps = {
  isPrivate: false,
};

export default routes;
