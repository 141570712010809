import React from 'react';
import CustomButton from 'components/CustomButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import './styles.css';

interface Props {
  children: (JSX.Element | JSX.Element[])[];
  title: string;
  description: string;
  editMode: boolean;
  type: 'profile' | 'network' | 'wallet';
  onClick: () => void;
  mainFunction: () => void;
}
const Index: React.FC<Props> = ({
  children,
  title,
  description,
  editMode,
  type,
  onClick,
  mainFunction,
}: Props) => {
  return (
    <>
      <div className="titleBigBackground">
        <span>{title}</span>
      </div>
      <p style={{ marginTop: 20 }}>{description}</p>
      {children}
      {editMode && type !== 'profile' && (
        <div className="addSubContentProfile">
          <CustomButton
            mode="spotlight"
            text={type === 'network' ? 'ADICIONAR SALA' : 'ADICIONAR CARTEIRA'}
            onClick={mainFunction}
          >
            <AddCircleOutlineIcon
              style={{ fontSize: 20, marginLeft: 5, marginBottom: 0.4 }}
            />
          </CustomButton>
        </div>
      )}
      <div style={{ marginTop: 20, display: 'flex', gap: 15 }}>
        {type !== 'profile' ? (
          <CustomButton
            text={editMode ? 'SAIR DA EDIÇÃO' : 'EDITAR'}
            mode="gray"
            small
            onClick={onClick}
          />
        ) : (
          <>
            {editMode ? (
              <>
                <CustomButton
                  text="CONFIRMAR"
                  mode="green"
                  small
                  onClick={mainFunction}
                />
                <CustomButton
                  text="CANCELAR"
                  mode="red"
                  small
                  onClick={onClick}
                />
              </>
            ) : (
              <CustomButton text="EDITAR" mode="gray" small onClick={onClick} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Index;
