import React, { createContext } from 'react';

import useAuth from './hooks/useAuth';

export type ContextProps = {
  authenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<string>;
  logout: () => boolean;
  checkAuth: (status: number) => void;
};

// TODO: Create a interface to context
const Context = createContext<ContextProps>({} as ContextProps);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const { authenticated, loading, login, logout, checkAuth } = useAuth();

  return (
    <Context.Provider
      value={{ loading, authenticated, login, logout, checkAuth }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AuthProvider };
