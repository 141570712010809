import React from 'react';

import './styles.css';

interface Props {
  active: boolean;
  primKey: string;
  secKey: string;
  primValue: string | number;
  secValue?: string | number;
  primValueR?: string;
  secValueR?: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const Index: React.FC<Props> = ({
  onClick,
  active,
  style,
  primKey,
  secKey,
  primValue,
  secValue,
  primValueR,
  secValueR,
}: Props) => {
  const secTitleStyle = active ? 'secundaryTitle' : 'secundaryTitle outlined';
  const mainTitleStyle = active ? 'mainTitle' : 'mainTitle outlined';
  const percentStyle = active
    ? 'percentCardDashBoard'
    : 'percentCardDashBoard outlined';
  const lineStyle = active ? 'line' : 'lineBlack';
  const boxStyle = active ? 'box' : 'box outlined';

  const newSecValueR = secValueR?.split('\\n').map((item) => <p>{item}</p>);
  return (
    <button type="button" className={boxStyle} style={style} onClick={onClick}>
      <div className="contentCardDashBoard">
        <div className="blockCardDashBoard">
          <span className={secTitleStyle}>{primKey}</span>
          <div className="valuesCardDashBoard">
            <span className={mainTitleStyle}>{primValue}</span>
            {primValueR && <span className={percentStyle}>{primValueR}</span>}
          </div>
        </div>
        <div className="blockCardDashBoard">
          <span className={secTitleStyle}>{secKey}</span>
          <div className="valuesCardDashBoard">
            <span className={mainTitleStyle}>{secValue}</span>
            {newSecValueR && (
              <span className={percentStyle}>{newSecValueR}</span>
            )}
          </div>
        </div>
      </div>
      <div className={lineStyle} />
    </button>
  );
};

Index.defaultProps = {
  style: {},
  primValueR: '',
  secValueR: '',
  secValue: '',
};

export default Index;
