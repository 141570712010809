import React from 'react';

import Account from './Account';
import Networks from './Networks';
import Wallets from './Wallets';

import './styles.css';

const Index: React.FC = () => {
  return (
    <div className="containerViewProfile">
      <Account />
      <div className="lineDiv" style={{ margin: '20px 0' }} />
      <Networks />
      <div className="lineDiv" style={{ margin: '20px 0' }} />
      <Wallets />
    </div>
  );
};

export default Index;
