/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './styles.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  changeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitHand: () => void;
  file: string;
}

const Index: React.FC<Props> = ({
  label,
  changeFile,
  submitHand,
  file,
}: Props) => {
  return (
    <div className="inputWithButton">
      <TextField
        label={label}
        fullWidth
        variant="outlined"
        margin="dense"
        disabled
        value={file}
        title={file}
      />
      <label htmlFor="upload" className="buttonSearch">
        PROCURAR
      </label>

      <input
        id="upload"
        type="file"
        accept="application/zip, application/rar"
        onChange={(e) => changeFile(e)}
        hidden
      />
      <Button onClick={submitHand} className="buttonSubmit">
        ENVIAR
      </Button>
    </div>
  );
};

export default Index;
