import React, { useContext, useEffect, useState } from 'react';
/* eslint-disable camelcase */
import { IWallet } from 'components/ViewDashboard/ViewProfile/Wallets';
import { INetwork } from 'components/ViewDashboard/ViewProfile/Networks';
import CustomButton from 'components/CustomButton';
import ModalDelete from 'components/ViewDashboard/ViewProfile/ModalDelete';
import ModalCoverage from 'components/InfoPlayerBox/ModalCoverage';
import ModalChangeRole from 'components/InfoPlayerBox/ModalChangeRole';
import ModalChangeDeal from 'components/InfoPlayerBox/ModalChangeDeal';
import ExpandCardFlex from 'components/ExpandCardFlex';
import DetailedHeaderInfo, {
  PlayerDetailedInfo,
} from 'components/InfoPlayerBox/DetailedHeaderInfo';

import './styles.css';
import { IPlayer } from 'components/ViewDashboard/ViewPlayers';
import api from 'services/api';
import swal from 'sweetalert';

import { Context } from 'context/authContext';

// Maybe with the backend data, this interface will change to Props
export interface PlayerInfo {
  playerName: string;
  detailedInfo: PlayerDetailedInfo;
  rooms: INetwork[];
  wallets: IWallet[];
}

export interface IBox extends IPlayer {
  players: IPlayer[];
  teamId: number;
  setPlayers: (value: IPlayer[]) => void;
}

const Index: React.FC<IBox> = ({
  id,
  profile,
  team,
  role,
  deal,
  beginning_date,
  players,
  teamId,
  setPlayers,
}: IBox) => {
  // const [deal, setDeal] = useState<number>(detailedInfo.deal);
  // const [role, setRole] = useState<string>(detailedInfo.position);
  const { checkAuth } = useContext(Context);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openChangeRoleModal, setOpenChangeRoleModal] =
    useState<boolean>(false);
  const [openChangeDealModal, setOpenChangeDealModal] =
    useState<boolean>(false);
  const [openCoverageModal, setOpenCoverageModal] = useState<boolean>(false);

  const [newRole, setRole] = useState<string>(role);
  const [newDeal, setDeal] = useState<number>(deal);

  const deletePlayer = (): void => {
    api
      .delete(`teams/${teamId}/members/${id}`)
      .then(() => {
        const listUpdated = players.filter((player) => player.id !== id);
        setPlayers(listUpdated);
        swal('Jogador removido com sucesso!', '', 'success');
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  };

  const translateRole = (roleEn: string): string => {
    switch (roleEn) {
      case 'player':
        return 'Jogador';
      case 'admin':
        return 'Administrador';
      default:
        return 'None';
    }
  };

  const saveNewRole = (): void => {
    api
      .patch(`teams/${teamId}/members/${id}`, { role: newRole })
      .then((resp) => {
        setRole(resp.data.role);
        const listUpdated = players.map((player) => {
          if (player.id === id) {
            return { ...player, role: translateRole(newRole) };
          }
          return player;
        });
        setPlayers(listUpdated);
        swal('Dados atualizados com sucesso!', '', 'success');
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  };

  const saveNewDeal = (): void => {
    api
      .patch(`teams/${teamId}/members/${id}`, { deal: newDeal })
      .then((resp) => {
        setDeal(resp.data.deal);
        const listUpdated = players.map((player) => {
          if (player.id === id) {
            return { ...player, deal: newDeal };
          }
          return player;
        });
        setPlayers(listUpdated);
        swal('Dados atualizados com sucesso!', '', 'success');
      })
      .catch((error) => {
        const msg = error.response?.data.detail;
        swal('Algum erro aconteceu', msg, 'error').then(() => {
          checkAuth(error.response?.status);
        });
      });
  };

  const detailedInfo = {
    beginDate: beginning_date,
    position: role,
    pendecies: 5,
    deal,
    limit: 3,
    money: 400,
    makeUp: -500,
    bank: 300,
    totalProfit: 5000,
    teamProfit: 3000,
  };

  return (
    <>
      <ExpandCardFlex
        headerHeight={116}
        details={
          <div>
            <hr
              style={{
                margin: '15px 10px 20px 10px',
              }}
            />
            <div className="detailedPlayerBoxContainer">
              <div className="columnPlayerContainer">
                <h3>Salas</h3>
                <div className="detailedPlayerContent">
                  {profile.networks.map((network) => (
                    <p key={network.id}>
                      {network.room}: {network.player}
                    </p>
                  ))}
                </div>
              </div>
              <div className="columnPlayerContainer">
                <h3>Carteiras</h3>
                <div className="detailedPlayerContent">
                  {profile.wallets.map((wallet) => (
                    <div key={wallet.id} style={{ marginBottom: 10 }}>
                      <p>Tipo: {wallet.name}</p>
                      <p>Nome: {wallet.holderName}</p>
                      <p>Número: {wallet.number}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr className="smoothLine" />
            <div className="inlineButtons">
              <div className="inlineButton">
                <CustomButton
                  text="COBERTURA"
                  small
                  onClick={() => setOpenCoverageModal(true)}
                  mode="gray"
                />
              </div>
              <div className="inlineButton">
                <CustomButton
                  text="EDITAR CARGO"
                  small
                  onClick={() => setOpenChangeRoleModal(true)}
                  mode="gray"
                />
              </div>
              <div className="inlineButton">
                <CustomButton
                  text="EDITAR DEAL"
                  small
                  onClick={() => setOpenChangeDealModal(true)}
                  mode="gray"
                />
              </div>
              <div className="inlineButton">
                <CustomButton
                  text="REMOVER JOGADOR"
                  small
                  onClick={() => setOpenDeleteModal(true)}
                  mode="red-outlined"
                />
              </div>
            </div>
          </div>
        }
        header={
          <div>
            <header className="expandedHeader">
              <div
                className="titleInfoPlayerBoxContainer"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                }}
              >
                <p
                  className="textTitleInfoPlayerBoxContainer"
                  style={{ color: 'black' }}
                >
                  {`${profile.first_name} ${profile.last_name}`}
                </p>
              </div>
              <hr
                style={{
                  height: 0,
                  width: '100%',
                  alignSelf: 'center',
                  margin: '0 10px 0 10px',
                  borderColor: 'white',
                  border: '1px solid white',
                  backgroundColor: 'white',
                }}
              />
            </header>
            <DetailedHeaderInfo detailedInfo={detailedInfo} />
          </div>
        }
      >
        <div className="infoPlayerBoxContainer">
          <div style={{ display: 'flex' }}>
            <div className="titleInfoPlayerBoxContainer">
              <p className="textTitleInfoPlayerBoxContainer">
                {`${profile.first_name} ${profile.last_name}`}
              </p>
            </div>
            <hr className="titleLineInfoPlayer" />
          </div>
          <DetailedHeaderInfo detailedInfo={detailedInfo} />
        </div>
      </ExpandCardFlex>
      <div>
        <ModalDelete
          open={openDeleteModal}
          title={`CONFIRMA QUE DESEJA REMOVER: 
            ${profile.first_name} ${profile.last_name}?`}
          onConfirm={deletePlayer}
          onClose={() => setOpenDeleteModal(false)}
        />
        <ModalChangeRole
          role={newRole}
          open={openChangeRoleModal}
          playerName={`${profile.first_name} ${profile.last_name}`}
          setRole={setRole}
          saveRole={saveNewRole}
          onClose={() => setOpenChangeRoleModal(false)}
          onSave={() => setOpenChangeRoleModal(false)}
        />
        <ModalChangeDeal
          deal={newDeal}
          open={openChangeDealModal}
          playerName={`${profile.first_name} ${profile.last_name}`}
          setDeal={setDeal}
          saveDeal={saveNewDeal}
          onClose={() => setOpenChangeDealModal(false)}
          onSave={() => setOpenChangeDealModal(false)}
        />
        <ModalCoverage
          teamId={teamId}
          profileId={profile.id}
          playerName={`${profile.first_name} ${profile.last_name}`}
          open={openCoverageModal}
          onClose={() => setOpenCoverageModal(false)}
          // coverageData={{}}
        />
      </div>
    </>
  );
};

export default Index;
