import React from 'react';
import './styles.css';

// interface Props {}

const index: React.FC = () => {
  const currentChart = 'profit';
  return (
    <div className="mainCashierContainer">
      <p>m</p>
      <p>o</p>
      <p>c</p>
      <p>k</p>
      <p>e</p>
      <p>d</p>
    </div>
  );
};

export default index;
