import React from 'react';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import Modal from 'components/Modal';

import './styles.css';

interface Props {
  playerName: string;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  deal: number;
  setDeal: (deal: number) => void;
  saveDeal: () => void;
}

const index: React.FC<Props> = ({
  playerName,
  onClose,
  onSave,
  open,
  deal,
  setDeal,
  saveDeal,
}: Props) => {
  return (
    <Modal open={open} setOpen={onClose}>
      <div>
        <header className="headerPlayerName">{playerName}</header>
        <div>
          <CustomInput
            min="0"
            label="Insira o novo deal."
            placeholder="Insira o deal do jogador."
            onChange={(e) => setDeal(e.target.value as unknown as number)}
            value={deal}
            type="number"
          />
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 15,
          }}
        >
          <CustomButton
            onClick={() => {
              saveDeal();
              onSave();
            }}
            text="SALVAR"
            mode="green"
          />
          <CustomButton onClick={onClose} text="CANCELAR" mode="red" />
        </div>
      </div>
    </Modal>
  );
};

export default index;
