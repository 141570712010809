import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Context } from 'context/authContext';

const Index: React.FC = () => {
  const { authenticated, logout } = useContext(Context);

  const handleLogout = (): void => {
    logout();
  };
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ textAlign: 'center', flexGrow: 1 }}>
          Sigma
        </Typography>
        {authenticated && (
          <Button
            onClick={handleLogout}
            color="inherit"
            style={{ position: 'absolute', right: 5 }}
          >
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Index;
